import {Component, Injector, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bundle.notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
  providers: []
})
export class NotfoundComponent {


}
