import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";


@Component({
    selector: 'bundle.new-user',
    templateUrl: './user.new.user.component.html',
    styleUrls: ['./user.new-user.component.scss'],
})

export class UserNewUserComponent extends BaseComponent implements OnInit{


  ngOnInit() {

  }

}
