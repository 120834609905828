<div class="user-subscription">


  <h3 class="header">{{'subscription_header_my_subscriptions'|trans}}</h3>

  <ng-container *ngIf="data">

    <ng-container *ngIf="messageMySubs">
      <app-core-message [message]="messageMySubs"></app-core-message>
    </ng-container>

    <ng-container *ngIf="data.length === 0;else subs ">
      <div>{{'subsciption_empty'|trans}}</div>
    </ng-container>
    <ng-template #subs>
    <div class="flexbox">
        <ng-container *ngFor="let sub of data">
          <div class="sub">
            <div class="title">{{sub.subscription.name}}</div>
            <div class="article-content desc" [innerHTML]="sub.subscription.description|safeHtml"></div>
            <div class="duration">{{'subscription_duration'|trans}} {{sub.subsTime }} {{getSuffixForDuration(sub.subsTime)|trans}}</div>
            <ng-container *ngIf="sub.paid; else notPaid">
              <div class="end">
                <span class="label">{{'subsciption_end'|trans}}</span> {{sub.timeEnd|date:'dd-MM-yyyy HH:mm'}}
              </div>
              <!--          <div class="paid">{{'subscription_paid'|trans}}</div>-->
            </ng-container>
            <ng-template #notPaid>
              <div class="not-paid">
                <div class="price">{{'subscription_price'|trans}} {{sub.price}}&euro;</div>
                <div class="button-container">
                  <span class="button-error" (click)="delete(sub.id)">{{'subscription_btn_delete'|trans}}</span>
                  <a class="button" target="_blank" href="https://winpecker.devdigitalberry.pl/pay/subscription/{{sub.id}}">{{'subscription_btn_pay'|trans}}</a>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>

      <div class="break"></div>
    </div>
    </ng-template>

  </ng-container>


  <h3 class="header">{{'subscription_header_all_subscriptions'|trans}}</h3>
  <ng-container *ngIf="message">
    <app-core-message [message]="message"></app-core-message>
  </ng-container>
  <div class="flexbox">
    <ng-container *ngFor="let sub of subscriptions">
      <div class="sub">
        <div class="title">{{sub.name}}</div>
        <div class="article-content desc" [innerHTML]="sub.description|safeHtml"></div>
        <div class="price">{{'subscription_price'|trans}} {{sub.price}}&euro;</div>
        <div class="duration">Czas trwania: {{sub.subsTime}} {{getSuffixForDuration(sub.subsTime)|trans}}</div>
        <div class="button-container">
          <div class="button" (click)="addSub(sub.id)">{{'subscription_btn_add'|trans}}</div>
        </div>
      </div>
    </ng-container>
    <div class="break"></div>
  </div>


</div>
