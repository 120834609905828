import {InjectionToken} from '@angular/core';
import {IAppConfig} from './iapp.config';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig = {
    routes: {
        berrycms: 'berrycms'
    },
    endpoints: {
        // berrycms: '/api',
        // serverApi: '/api',
        berrycms: 'https://www.winpecker.devdigitalberry.pl/api',
        serverApi: `https://www.winpecker.devdigitalberry.pl/api`,
    },
    oauth: {
        url: '/app_dev.php/oauth/v2',
        client_id: 'mkk-priv',
        client_secret: 'asdf',
    },
    owner: null,
    language: 'pl',
    currency: {},
    structurePageList: [],
    pageList: {},
    showRealization: true,
    domain: null,
    photo: {
        lazyLoading: false,
        quality: {
            320: 60,
            400: 60,
            576: 60,
            768: 70,
            992: 80,
            1200: 80,
            1360: 80,
            1920: 80,
            2560: 90,
        }
    },
    mobile: {
        sizes: [
            320,
            400,
            576,
            768,
            992,
            1200,
            1360,
            1920,
            2560,
        ],
    },
    realization: [
        // {
        //     label: 'CUTBerry',
        //     url: 'http://www.cutberry.com',
        // },
        // {
        //     label: '+',
        // },
        {
            label: 'DigitalBerry',
            url: 'http://www.digitalberry.pl'
        }
    ]
};

