import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {FacebookService} from "ngx-facebook";
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'bundle.user-job-offer-recruiter',
    templateUrl: './user.job.offer.recruiter.component.html',
    styleUrls: ['./user.job.offer.recruiter.component.scss'],
})

export class UserJobOfferRecruiterComponent extends BaseComponent implements OnInit{

  constructor(protected _injector: Injector,private fbk: FacebookService, protected meta: Meta) {
    super(_injector);
    // this.fbk.init({
    //   appId: 'yourappid', cookie: true, status: true, xfbml: true, version: 'v2.8'
    // });
    //
    // (function (d, s, id) {
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id))
    //     return;
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "//connect.facebook.net/en_US/all.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
  }

  ngOnInit() {

    this.apiService.getByStaticUrl(ApiLink('/user-offers')).subscribe(data => {
      console.log('offers',data);
      this.data = data;
    })

    this.apiService.getByStaticUrl(ApiLink('/user-offers-rs')).subscribe(data => {
      console.log('/user-offers-rs',data);
      // this.data = data;
    })

    // this.apiService.getByStaticUrl(ApiLink('/user-offers')).subscribe(data => {
      // console.log('offers',data);
      // this.data = data;
    // })
  }

  share() {
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'websitename' });
    this.meta.updateTag({ property: 'og:title', content: 'title'});
    this.meta.updateTag({ property: 'og:description', content: 'Description'});
    this.meta.updateTag({ property: 'og:image', content: 'url' });

    // this.fbk.ui({
    //   method: 'share_open_graph',
    //   action_type: 'og.shares',
    //   action_properties: JSON.stringify({
    //     object: {
    //       'og:title': 'title',
    //       'og:description': 'description',
    //       'og:image': 'imagelink',
    //       'og:url': 'referlink',
    //     }
    //   })
    // });
  }

}
