import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {FormFormService} from "../../../../services/form.api.service";
import {CustomSelectOptionModel} from "../../../form/customselect/customSelectOptionModel";
import { DatePipe } from '@angular/common';
import {BundleUserMonthlyComponent} from "../../../components/user-monthly/bundle.user.monthly.component";

@Component({
    selector: 'bundle.user-workinghours',
    templateUrl: './user.workinghours.component.html',
    styleUrls: ['./user.workinghours.component.scss'],
  providers: [DatePipe]
})

export class UserWorkinghoursComponent extends BaseComponent implements OnInit{

  message

  jobOffers = [];

  // stats = [];

  datePipe;

  date = new Date();

  // @ViewChild('statsComponent', {static: true}) statsComponent = BundleUserMonthlyComponent;

  @Input() me;

  ngOnInit() {
    this.datePipe = this._injector.get(DatePipe);

    this.apiService.getByStaticUrl(ApiLink('/user-offers')).subscribe(data => {
      console.log('offers', data);
      this.jobOffers = data;
    })
    this.getWorkingHours();
    // this.getStats();
  }

  getWorkingHours(date = this.date) {
    this.apiService.getByStaticUrl(ApiLink(`/user-working-hours?date=${this.datePipe.transform(date, 'yyyy-MM-dd')}`)).subscribe(data => {
      this.data = data;
    });

  }


  changeMonth(date) {
    this.date = date;
    // @ts-ignore
    // this.statsComponent.date = date;
    this.getWorkingHours()
  }

  dateDiff(date:string, date1:string) {
    let d = new Date(date);
    let d1 = new Date(date1);
    let minutes = (d1.getTime() - d.getTime()) / (1000 * 60);
    let minDisplay = minutes%60 < 10 ? '0' + minutes%60 : minutes%60;
    return `${Math.floor(minutes/60)}:${minDisplay}h`
  }

  createFormLink() {
    return ApiLink(`/working-hours/declaration`);
  }

  submitFormAction($event) {
    $event.preventDefault();
    let $form = $event.srcElement;
    let url = $form.getAttribute('action');

    this.apiService.staticPost(url, new FormData($form)).subscribe(response => {
      this.message = response;
      if (response.type === 'success'){
        this.getWorkingHours();
      }
    })


  }
  getOptions(elements, key, label) {
    if (elements){
      return elements.map(el => {
        return new CustomSelectOptionModel(el[key], el[label]);
      });
    }else {
      return  new CustomSelectOptionModel('', null);
    }
  }


}
