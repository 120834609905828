import {Component, ElementRef, Injector, Input, ViewChild} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {CoreMessage} from "../../../services/CoreMessage";
import {FormFormService} from "../../../services/form.api.service";
import {AppService} from "../../../core/services/app.service";
import {ApiLink, getLocale, link} from "../../../utils/utils";
import {FormBaseComponent} from "../../form.base.component";
import {ActivatedRoute} from "@angular/router";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
  selector: 'bundle.login',
  // templateUrl: './../../form/form/bundle.form.form.component.html',
  templateUrl: 'login.component.html',
  styleUrls: ['./user.loginForm.component.scss'],
})

export class UserLoginFormComponent extends FormBaseComponent{

    @Input() ngModel;
    formForm: any;
    message: CoreMessage;
    forValidationErrorList = [];
    private postData : any;
    isRemindPassword = true;
    formService


    // constructor(public structurePageService: StructurePageService,
    //             private appService:AppService,
    //             private formService: FormFormService,
    //             private router: ActivatedRoute,
    //             private router1: Router,
    // ){
    //     super(structurePageService);
    // }


    // constructor(   protected _injector: Injector, protected appService: AppService, private route: ActivatedRoute) {
    //   super( _injector);
    // }



    ngOnInit() {
      this.formService = this._injector.get(FormFormService)
        this.apiService.getByStaticUrl(ApiLink('/cms/front_forms/3')).subscribe(data => {
          this.formForm = data;
        })
    }

    submitPost(form, $event, formvalue) {
      try {
        this.formService.clearFormMessage(this.formForm.formKey);
      }catch (err) {}

      let postData = new FormData($event.target);

      var object = {};
      postData.forEach((value, key) => {
        // Reflect.has in favor of: object.hasOwnProperty(key)
        if(!Reflect.has(object, key)){
          object[key] = value;
          return;
        }
        if(!Array.isArray(object[key])){
          object[key] = [object[key]];
        }
        object[key].push(value);
      });

      this.message = null;

      this.appService.obtainAccessToken(object).subscribe(data => {

        if(data['type'] == 'error') {
            this.message = new CoreMessage('error',getLocale(data['header']),getLocale(data['content']),true);
        }else{
            this.appService.saveToken(data);
            this.message = new CoreMessage('success',getLocale('oauth_login_success_header'),getLocale('oauth_login_success_content'),true);
        }
        let redirect = this.route.snapshot.queryParams['redirect'] || link(getLocale('page_id_my_account'))
        this.urlService.redirect(redirect)
      }, error => {
          console.log(error);
            this.message = new CoreMessage('error', 'login_error_header', 'login_error_content')
        });

    }

}
