<div class="user-workinghours-not-approved">

  <div class="article-content">

    <table>
      <thead>
      <tr>
        <th>{{'user_working_hours_employer_not_approved_table_user'|trans}}</th>
        <th>{{'user_working_hours_employer_not_approved_table_job'|trans}}</th>
        <th>{{'user_working_hours_employer_not_approved_table_time'|trans}}</th>
        <th>{{'user_working_hours_employer_not_approved_table_time_diff'|trans}}</th>
        <th></th>
<!--        <th>{{'user_working_hours_employer_table_time_status'|trans}}</th>-->
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let object of data">
        <tr>
          <td>
            {{object.workingUser?.email}}
            <small>{{object.workingUser?.firstname}} {{object.workingUser?.surname}}</small>
          </td>
          <td><a [href]="'page_id_offer'|trans|link:object.jobOffer.slug:object.jobOffer.cmsid">{{object.jobOffer.title}}</a></td>
          <td>
            <div class="time">
              <div>od <b>{{object.timeStart|date:'dd-MM-YYYY HH:mm'}}</b></div>
              <div>do <b>{{object.timeEnd|date:'dd-MM-YYYY HH:mm'}}</b></div>
            </div>
          </td>
          <td>{{dateDiff(object.timeStart, object.timeEnd)}}h</td>
          <td>
            <div class="btn-container">
              <span class="button-link" (click)="accept(object.id, $event)">{{'user_working_hours_employer_not_approved_btn_accept'|trans}}</span>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>


  </div>



</div>
