<div class="flexbox">
  <ng-container *ngIf="_data && _data.length > 0">
    <ng-container *ngIf="data[0] && data[0].allButton != 'false'">
      <div class="button-gray" [ngClass]="{'button-blue': params?.cmsid === 0, 'button-gray': params?.cmsid !== 0}"
           [routerLink]="createUrl()">{{'category_all'|trans}}</div>
    </ng-container>
    <!--         routerLink="{{urlService.getUrl() +',wszystkie,0'}}">Wszystkie</div>-->

    <ng-container *ngFor="let element of _data">
      <div [ngClass]="{'button-blue': element.url|isUrlVisit, 'button-gray': (element.url|isUrlVisit) == false}"
           routerLink="{{element.url}}">
        {{element.txt}}
      </div>
    </ng-container>
  </ng-container>
  <ng-content></ng-content>
</div>
