<div class="flexbox">

  <app-core-svg [url]="'/assets/staticresource/project/Group54.svg'"></app-core-svg>
  <div>
    <div class="label">{{"footer_owner_contact"|trans}}</div>
    <h6>{{getOwner().phone}}</h6>
    <div class="break"></div>
    <div class="label">{{"footer_owner_contact_email"|trans}}</div>
    <h6>{{getOwner().email}}</h6>
    <div class="flexbox icons">
      <a href="{{'linkedin'|trans}}">
        <app-core-svg [url]="'/assets/staticresource/project/linkedin.svg'"></app-core-svg>
      </a>
      <a href="{{'facebook'|trans}}">
        <app-core-svg [url]="'/assets/staticresource/project/facebook.svg'"></app-core-svg>
      </a>
    </div>
  </div>

</div>
