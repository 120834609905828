import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {AbstractBoxComponent} from '../abstract.box.component';
import Box from './bundle.box.component.interface';


@Component({
  selector: 'bundle.box',
  templateUrl: './bundle.box.component.html',
  styleUrls: ['./bundle.box.component.scss'],
  providers: []
})

export class BundleBoxComponent extends AbstractBoxComponent{

  public _data: Box

}
