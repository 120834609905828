import {Component, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CoreDomain} from './domain.model';
import {Observable} from 'rxjs/Observable';

import {CoreDomainBaseService} from './domain.base.service';
import {AppService} from '../../../../core/services/app.service';
import {AppConfig} from '../../../../config/app.config';
import {catchError, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class CoreDomainService extends CoreDomainBaseService {

    getDefault(): Observable<any> {
      return this.http.get(this.apiUrl + '/' + AppConfig.language + '/core/domains?is_default=1', this.appService.getOptions())
        .pipe(
        map((response:any) => {
          try {
            this.request$.emit('finished');
            return response.shift();
          }catch (err){
            return {};
          }
        })
        ,catchError(error => this.handleError(error))
    )
    }
}
