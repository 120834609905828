<div class="user-profile">
  <bundle class="category-nav">

    <ng-container *ngIf="pagesService && me">
      <ng-container *ngFor="let p of pagesService.getPageForWorkerType(me.role.role)">
        <span [ngClass]="{'button-blue': p.page === page, 'button-gray': p.page !== page}"
          [routerLink]="'page_id_my_account'|trans|link" [queryParams]="{ page: p.page}">{{p.name|trans}}</span>
      </ng-container>
    </ng-container>
<!--    [ngClass]="{'button-blue': element.url|isUrlVisit, 'button-gray': (element.url|isUrlVisit) == false}"-->
<!--    <span routerLink="/moje-konto" [queryParams]="{ page: 'oferty-pracy'}">Oferty Pracy</span>-->
<!--    <span routerLink="/moje-konto" [queryParams]="{ page: 'godziny-pracy'}">Godziny pracy</span>-->
<!--    <span routerLink="/moje-konto" [queryParams]="{ page: 'dokumenty'}">Moje dokumenty</span>-->
<!--    <span routerLink="/moje-konto" [queryParams]="{ page: 'konsultacje'}">Konsultacje</span>-->
<!--    <span routerLink="/moje-konto" [queryParams]="{ page: 'konsultacje'}">Kursy</span>-->
  </bundle>


  <ng-container *ngIf="page">
<!--    {{pagesService.getBundleByPage(page)}}-->
    <app-dynamic-selector [inputs]="{me: me}" [componentSelector]="'bundle.' + pagesService.getBundleByPage(page)"></app-dynamic-selector>

  </ng-container>

</div>
