import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnInit, Output, EventEmitter} from '@angular/core';


import {buildFormData, getLocale} from '../../../utils/utils';
import {CoreMessage} from '../../../services/CoreMessage';
import {FormFormService} from '../../../services/form.api.service';
import {FormForm} from "../../../models/FormForm.model";
import {BaseComponent} from "../../base.component";
import {FormBaseComponent} from "../../form.base.component";
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl"


@Component({
    selector: 'bundle.form',
    templateUrl: './bundle.form.form.component.html',
    styleUrls: ['./bundle.form.form.component.scss'],
    providers: []
})

export class BundleFormFormComponent extends FormBaseComponent {
  files: any;
  // message: CoreMessage;
  messageNoOptions: CoreMessage;

  @Output()
  submitEvent = new EventEmitter()

  submitPost($form, $event, formvalue) {
    if (this.submit && typeof this.submit === "function"){
      this.submit.call(this, $form, $event, formvalue)
    }else {
      $event.preventDefault();
      this.formService.clearFormMessage(this.formForm.formKey);
      this.formService.send(new FormData($event.target), this.urlSend).subscribe(response => {
        let result = this.formService.retriveResponse(this.formForm.formKey, response, false, true);
        if (result instanceof CoreMessage) {
          this.message = result;
        } else {
          this.forValidationErrorList = result;
        }
        this.submitEvent.emit(response);
      })
    }
  }

}
