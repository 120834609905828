import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'bundle.date-changer',
  templateUrl: './bundle.date.changer.component.html',
  styleUrls: ['./bundle.date.changer.component.scss']
})

export class BundleDateChangerComponent implements OnInit {

  @Input()
  format = "dd.MM.yyyy"

  @Input()
  startDate = new Date();

  @Input()
  changeType = 1;

  @Output()
  changeEvent = new EventEmitter<Date>();

  constructor() {}

  ngOnInit(): void {
  }

  change(isPrev = false) {
    let d = this.startDate;
    switch (this.changeType) {
      case 0:
        break;

      case 1:
        this.startDate = new Date(d.getFullYear(), isPrev ? d.getMonth() - 1 : d.getMonth() + 1, 1);
        break;

      case 2:
        break;
    }
    this.changeEvent.emit(this.startDate);
  }


}
