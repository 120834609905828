import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {ApiLink} from "../../../utils/utils";
import {AppPartnerService} from "../../../shared/bundles/app/partner/partner.service";


@Component({
  selector: 'bundle.custom-section',
  templateUrl: './bundle.custom.section.component.html',
  styleUrls: ['./bundle.custom.section.component.scss']
})

export class BoxCustomSectionComponent extends BaseComponent implements OnInit {

  parnetrs = []

  ngOnInit(): void {
    this._injector.get(AppPartnerService).getAll().subscribe(partners => {
      console.log('partners',partners);
      this.parnetrs = partners;
    })
    // this.apiService.getByStaticUrl(ApiLink('/'))
  }


}
