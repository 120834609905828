import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PagesMap {

  pages: any = null;

  constructor() {
    this.pages = pagesMap;
  }

  getPageForWorkerType(workerType: number) {
    return this.pages.filter(el => el.workerType.includes(workerTypes[workerType]))
  }

  getBundleByPage(page:string){
    return pagesMap.find(el => el.page === page)?.bundle
  }

  getDefaultPage() {
    return this.pages.find(el => el.page === 'profile')
  }

  // getWorkerTypes() {
  //   return workerTypes;
  // }

}

export enum workerTypes {
  ROLE_WORKER,
  ROLE_RECRUITER,
  ROLE_EMPLOYER
}

let pagesMap = [
  {
    page: 'profile',
    name: 'page_name_profile',
    bundle: 'user-profile',
    workerType: [0,1,2]
  },
  {
    page: 'job-offers',
    name: 'page_name_job_offers',
    bundle: 'user-job-offer',
    workerType: [0]
  },
  {
    page: 'working-hours',
    name: 'page_name_working_hours',
    bundle: 'user-workinghours',
    workerType: [0]
  },
  {
    page: 'consultation',
    name: 'page_name_consultations',
    bundle: 'user-consultation',
    workerType: [0,1,2]
  },
  {
    page: 'courses',
    name: 'page_name_courses',
    bundle: 'user-courses',
    workerType: [0,1,2]
  },
  {
    page: 'subscriptions',
    name: 'page_name_subscriptions',
    bundle: 'user-subscription',
    workerType: [0,1,2]
  },
  {
    page: 'new-user',
    name: 'page_name_new_user',
    bundle: 'new-user',
    workerType: [1,2]
  },
  {
    page: 'job-offers-recruiter',
    name: 'page_name_job_offers_recruiter',
    bundle: 'user-job-offer-recruiter',
    workerType: [1]
  },
  {
    page: 'working-hours-recruiter',
    name: 'page_name_working_hours_recruiter',
    bundle: 'user-workinghours-recruiter',
    workerType: [1]
  },
  {
    page: 'working-hours-employer',
    name: 'page_name_working_hours_employer',
    bundle: 'user-workinghours-employer',
    workerType: [2]
  },
  {
    page: 'working-hours-employer-not-approved',
    name: 'page_name_working_hours_employer_not_approved',
    bundle: 'user-workinghours-employer-not-approved',
    workerType: [2]
  },
  {
    page: 'invoices',
    name: 'page_name_invoices',
    bundle: 'user-invoices',
    workerType: [2]
  },
  {
    page: 'paid',
    name: '',
    bundle: 'user-after-pay',
    workerType: []
  },
];
