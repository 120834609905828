<div class="course-article">
  <ng-container *ngIf="data">

    <div class="category">{{data?.category.name}}</div>
    <h3 class="title">{{data.title}}</h3>
<!--    <div class="flexbox">/-->
<!--      <div>-->
    <div class="flexbox price">
      <app-core-svg [url]="'/assets/staticresource/project/dollar.svg'"></app-core-svg>
      <span>{{data.preprice}}</span>
    </div>

    <div class="article-content" [innerHTML]="data.precontent|safeHtml"></div>

    <div class="article-content" [innerHTML]="data.content|safeHtml"></div>
<!--      </div>-->

<!--    </div>-->

    <ng-container *ngIf="message">
      <app-core-message [message]="message"></app-core-message>
    </ng-container>

    <div class="btn-container">
      <span class="button" (click)="join()">{{'btn_course_join'|trans}}</span>

      <a class="button" [href]="'btn_id_courses'|trans|link">{{'btn_course_back'|trans}}</a>
    </div>

  </ng-container>
</div>
