import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';

@Pipe({
  name: 'href',
  pure: false
})

export class HrefPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value.includes('http://') || value.includes('https://')){
      return value;
    }
    // else {
    //   return `${AppConfig.endpoints.serverApi.replace(/\/api/g, '')}${value[0] === "/" ? value : '/' + value}`
    // }
    if (AppVariables.Languages) {
      if (AppVariables.Languages.isCurrentLangDefault()) {
        return value
      }else {
        return `/${AppConfig.language}${value[0] === "/" ? value : '/' + value}`
      }
    }
    return value
  }
}
