import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from "../../base.component";
import {CmsElementApiService} from "../../../services/cmsElement.api.service";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'bundle.blog-article',
  templateUrl: './bundle.blog.article.component.html',
  styleUrls: ['./bundle.blog.article.component.scss'],
  providers: []
})

export class BundleBlogArticleComponent extends BaseComponent implements OnInit{

  protected cmsElementApiService

  constructor(protected _injector: Injector, protected metaService: Meta) {
    super(_injector);
  }

  ngOnInit() {
    this.cmsElementApiService = this._injector.get(CmsElementApiService);
    this.cmsElementApiService.getByCmsId(this.urlService.params['cmsid']).subscribe(data => {
      this.data = data.length === 1 ? data[0] : data;
      this.metaService.updateTag({ name: 'og:title', content: data.title})
      this.metaService.updateTag({ name: 'og:image', content: data.photo})

    })

  }

}
