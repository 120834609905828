import {Component, Input, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogEntryService} from '../../../shared/bundles/app/blog_entry/blog_entry.service';
import {UrlService} from '../../../services/urlService';
import {BaseComponent} from '../../base.component';
import {CMSCMSElementService} from '../../../shared/bundles/cms/cms_element/cms_element.service';
import {PaginationComponent} from '../../../core/pagination/pagination.component';
import {AppVariables} from '../../../config/app.variables';
import Opinions from "./bundle.opinions.list.interface";

@Component({
  selector: 'bundle.opinions-list',
  templateUrl: './bundle.opinions.list.component.html',
  styleUrls: ['./bundle.opinions.list.component.scss'],
  providers: []
})

export class BundleOpinionsListComponent extends BaseComponent implements OnInit{

  _data: Opinions[]

  ngOnInit() {
  }
}
