import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// import {FormForm} from './form.model';
import {Observable} from 'rxjs/Observable';
import {ApiService} from './api.service';
import {AppService} from '../core/services/app.service';
import {AppConfig} from '../config/app.config';
import {catchError, map} from 'rxjs/operators';
import {CoreMessage} from './CoreMessage';
import {ApiLink, getLocale} from "../utils/utils";

// import {FormFormBaseService} from "./form.base.service";
// import {AppService} from "../../../../core/services/app.service";
// import {AppConfig} from "../../../../config/app.config";
// import {CoreMessage} from "../../core/message/message.model";


@Injectable({
  providedIn: "root"
})
export class FormFormService extends ApiService {

  createFormField(label = null, fieldType, defaultValue, name){
    let def = {
      textLabel: label,
      fieldType: fieldType,
      defaultValue: defaultValue,
      textDescription:null,
      formFieldName:name,
    }
    return Object.create(def);
  }

  createFormSection(name, desc, fields = [] ) {
    let section = {
      name: name,
      description: desc,
      fields: fields,
      addField(field) {
        this.fields.push(field)
      }
    }
    return Object.create(section)
  }

  createForm(name, key, desc, sections = []) {
    let form = {
      formName: name,
      formKey: key,
      formDescription: desc,
      sections: sections,
      addSection(section){
        this.sections.push(section)
      },
      setSections(sections) {
        this.sections = sections
      }
    }
    return Object.create(form);
  }

  sendOnEmail(message: any) {
    alert("sendOnEmail")
    var url = this.apiUrl+'/'+AppConfig.language+'/formforms';
    return this.http.post(url, message, this.appService.getOptions()).pipe(
      map(res =>  res)
    );
  }

  getOneByServiceName(serviceName: string, id:number = null): Observable<any> {
    this.request$.emit('starting');
    if(id > 0) {
      serviceName+='____'+id.toString();
    }
    return this.http.get(this.apiUrl+'/'+AppConfig.language+'/formforms/'+serviceName, this.appService.getOptions()).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    )
  }

  send(data: any, url = ApiLink('/front-form')) {
    return this.http.post(url, data, this.appService.getOptions(false,
      {'Content-Type': 'application/x-www-form-urlencoded'}
      )).pipe(
      map(res =>  res)
    );
  }

  /**
   * Wyczyszczenie komunikatów walidacji oraz powiadomień dot. formularza
   * @param formKey
   */
  clearFormMessage(formKey : string)
  {
    /** usuniecie komunikatów walidacji oraz klas dot. błedów**/
    var validatorMessageList = document.getElementById('generatedForm_'+formKey).getElementsByClassName('error_message');
    if(validatorMessageList.length>0)
    {
      while(validatorMessageList[0]) {
        validatorMessageList[0].parentNode.removeChild(validatorMessageList[0]);
      }
    }
    var formComponentList = document.getElementById('generatedForm_'+formKey).getElementsByClassName('form_component error');

    if(formComponentList.length>0)
    {
      while(formComponentList[0]) {
        formComponentList[0].classList.remove('error');
      }
    }
  }

  /**
   * Przygotowanie danych do wysyłki
   * @param formKey
   * @param data
   * @returns {{}}
   */
  retriveData(formKey : string, data, formId = 3)
  {
    var postData = {};
    postData['data'] = data;
    postData['formKey'] = formKey;
    postData['front-form'] = formId;

    return postData;
  }

  retriveResponse(formKey, data, succesAsFlash : boolean = false, errorAsFlash : boolean = false) {
    if(data) {
      if (data['type'].includes('error')) {
        let forValidationErrorList = data['data'];
        /** komunikaty walidacji dla pól **/
        Object.keys(forValidationErrorList).forEach(key => {
          var messageList = forValidationErrorList[key];
          if (messageList.length > 0) {
            var htmlMessage = '<div class="clear"><!-- --></div><span class="error_message">';
            messageList.forEach(function (el) {
              htmlMessage += '<span>' + getLocale(el,false, true) + '</span>';
            });
            htmlMessage += '</span>';

            var parent = document.getElementById('generatedForm_' + formKey + '_field_' + key);
            parent.parentElement.classList.add('error');
            parent.parentElement.lastElementChild.insertAdjacentHTML('afterend', htmlMessage);
          }
        });

        /** komunikat nad formularzem **/
        var htmlMessage = '<div class="clear"><!-- --></div><span class="short_error_message">';
        htmlMessage += '<span>' + data['content'] + '</span>';
        htmlMessage += '</span>';
        var parent = document.getElementById('generatedForm_' + formKey);
        parent.getElementsByClassName('formGroup');
        this.scrollToFormContainer(formKey);
        return forValidationErrorList;
      } else {
        let flash = false;
        if (data['type'] == 'success') {
          flash = succesAsFlash;
        }
        if (data['type'] != 'success') {
          flash = errorAsFlash;
        }
        if (flash === false) {
          var params = document.getElementById('generatedForm_' + formKey).getElementsByClassName('formGroup');
          while (params[0]) {
            params[0].parentNode.removeChild(params[0]);
          }
        }
        return new CoreMessage(data['type'], data['header'], data['content'], flash);
      }

    }
  }



  scrollToFormContainer(formKey)
  {
    document.getElementById('generatedForm_'+formKey).scrollIntoView();
  }

  /**
   * Akcja ma na selu automatyczny wybów oopcji dla wskazanego klucza w przypadku jeśli jest tylko jedna opcja do wyboru
   * @param {FormForm} form
   * @param {string} fieldName
   */
  autoselect(form: any, fieldName: string, delay=300)
  {
    var fieldOptionHtmlId = 'generatedForm_'+form.formKey;

    if(form.formFormSections && form.formFormSections.length>0 )
    {
      form.formFormSections.forEach(function(item){
        if(item && item.formSectionFields && item.formSectionFields.length >0)
        {
          item.formSectionFields.forEach(function(oneField) {
            if(oneField && oneField.fieldKey == fieldName)
            {
              fieldOptionHtmlId+='_field_'+oneField.fieldKey;
              if(oneField.fieldOptions) {
                if (oneField.fieldOptions.length == 0) {

                }
                if (oneField.fieldOptions.length > 0) {
                  setTimeout(function () {
                    fieldOptionHtmlId += '_' + oneField.fieldOptions[0]['key'];
                    var htmlControl = document.getElementById(fieldOptionHtmlId);
                    if (htmlControl) {
                      htmlControl.click();
                    }
                  }, delay);
                }
              }
            }
          });
        }
      });
    }
  }

}
