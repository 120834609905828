<!--<div class="wrapper">-->

<!--<div class="flexbox" *ngIf="_elements && _elements.length > 0">-->
<!--  <div class="button-gray" [ngClass]="{'button-blue': selectedElement == null, 'button-gray': selectedElement != null}" routerLink="/pl/blog" [queryParams]="{}">Wszystkie</div>-->
<!--  <ng-container *ngFor="let element of _elements">-->
<!--    <div class="button-gray" [ngClass]="{'button-blue': selectedElement == element.id, 'button-gray': selectedElement != element.id}" routerLink="/pl/blog" [queryParams]="{id: element.id}">-->
<!--      {{element.categoryName}}</div>-->
<!--  </ng-container>-->
<!--</div>-->

<div>
  <ng-container *ngIf="_data">
    <bundle class="category-nav" [elements]="_data">
<!--      <div class="button-blue">Wszystkie</div>-->
<!--      <div class="button-gray">Holandia</div>-->
<!--      <div class="button-gray">Niemcy</div>-->
<!--      <div class="button-gray">Praca z własną działanością</div>-->
    </bundle>
  </ng-container>


</div>

<!--</div>-->
