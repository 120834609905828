<div class="custom-section flexbox">


  <div>
    <div class="box-container">
      <bundle class="box orange" [boxId]="'box_id_custom_section_orange'|trans"></bundle>

      <bundle class="box blue" [boxId]="'box_id_custom_section_blue'|trans"></bundle>
<!--    </div>-->
<!--    <div class="box-container" >-->
      <bundle class="box red" [boxId]="'box_id_custom_section_red'|trans"></bundle>

      <div class="black box" id="box-partners">
        <h4>{{"custom_section_black_header"|trans}}</h4>
        <div class="flexbox">
        <ng-container *ngFor="let partner of parnetrs">
          <a class="partner" href="{{partner.url}}">
            <app-core-image [url]="'https://winpecker.devdigitalberry.pl'+partner.logo" [width]="280" [height]="90" [quality]="80" [method]="'resize'"></app-core-image>
            {{partner.name}}
          </a>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

  <bundle class="box boxh2" [boxId]="'box_id_custom_section'|trans"></bundle>

</div>
