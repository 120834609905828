import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppRecruitmentsService} from '../../../shared/bundles/app/recruitments/recruitments.service';

@Component({
  selector: 'bundle.recruitment-list',
  templateUrl: './bundle.recruitment.list.component.html',
  styleUrls: ['./bundle.recruitment.list.component.scss'],
  providers: []
})

export class BundleRecruitmentListComponent implements OnInit{
    public data;
    protected _slot;

    constructor(
      protected converter: ConverterService,
      protected apiService: ApiService,
      protected recruitmentsService: AppRecruitmentsService
    ) {
      // super();
    }

    @Input()
    set slot(slot) {
      // this._slot = slot;
    };

    ngOnInit() {
      this.recruitmentsService.getAll().subscribe(data => {
        this.data = data;
      })
      // this.apiService.getByStaticUrl(this._slot.dataUrl).subscribe(data => {
      //   this.data = this.converter.convert(data, this._slot.dataEntity, this._slot.plugtemplate.plugKey);
      //   console.log(this.data);
      // })
    }
}
