import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from "../../base.component";

@Component({
  selector: 'bundle.blog-card',
  templateUrl: './bundle.blog.card.component.html',
  styleUrls: ['./bundle.blog.card.component.scss'],
  providers: []
})

export class BundleBlogCardComponent extends BaseComponent implements OnInit{

    // constructor(
    //   protected converter: ConverterService,
    //   protected apiService: ApiService
    // ) {
    //   // super();
    // }

    ngOnInit() {

    }

  protected onSetData(data: any = this._data) {
      super.onSetData(data);
  }

  // getTags(tagsElements = []) {
  //     return tagsElements.map(el => el.tag).map(el => `<span class="tag">#${el}</span>`).join("");
  //   }
}
