<ng-container *ngIf="data">
  <div class="element" [routerLink]="'page_id_offer'|trans|link:data.slug:data.cmsid">
    <div class="date">Dodano: {{data?.createdAt|date:'dd.MM.yyyy'}}</div>
    <h4 class="title">{{data?.title}}</h4>
    <div class="content article-content" [innerHTML]="data?.precontent"></div>
    <div class="flexbox details-container">
      <div class="flexbox localization">
        <app-core-svg [url]="'/assets/staticresource/project/placeholder.svg'"></app-core-svg>
        <span>{{data?.city.country.countryName}}, {{data?.city.city}}</span>
      </div>
      <div class="flexbox offert_type">
        <app-core-svg [url]="'/assets/staticresource/project/clock.svg'"></app-core-svg>
        <span>{{data?.times?.name}}</span>
      </div>
    </div>

    <a href="" class="button-underline-green flexbox">
      <span>{{'button_offer_more'|trans}}</span>
      <app-core-svg [url]="'/assets/staticresource/project/next.svg'"></app-core-svg>
    </a>
  </div>
</ng-container>
