import {Directive, ElementRef, OnInit} from '@angular/core';
import flatpickr from "flatpickr";
import {Polish} from "flatpickr/dist/l10n/pl";

@Directive({
  selector: '[datepicker]'
})
export class DatepickerDirective implements OnInit{

  constructor(private el: ElementRef) { }

  ngOnInit() {
    flatpickr(`#${this.el.nativeElement.getAttribute('id')}`, {
      "locale": Polish,
    });
  }

}
