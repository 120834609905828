import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from "../../../core/services/app.service";
import {BaseComponent} from "../../base.component";
import {ApiLink, getLocale} from "../../../utils/utils";
import {ActivatedRoute} from "@angular/router";
import {PagesMap, workerTypes} from "../pagesmap";


@Component({
    selector: 'bundle.account',
    templateUrl: './user.account.component.html',
    styleUrls: ['./user.account.component.scss'],
})

export class UserAccountComponent extends BaseComponent implements OnInit{


    page = null;
    me = null;

    constructor(protected _injector: Injector,
                protected appService: AppService,
                private route: ActivatedRoute,
                public pagesService: PagesMap
                ) {
      super(_injector);
    }

    ngOnInit() {

      this.route.queryParams.subscribe(params => {
          this.page = undefined;
          if (this.appService.checkCredentials()) {
            this.page = params['page'] || this.pagesService.getDefaultPage().page;
          }
        }
      );
      this.urlService.urlParams.subscribe(params => {
        if (!this.appService.checkCredentials()) {
          window.location.replace(getLocale('page_url_login'))
        }else {
           this.apiService.getByStaticUrl(ApiLink('/me'), this.appService.getOptions(false)).subscribe(data => {
             this.me = data;
           })
        }
      })
    }



}
