import {Component, Input, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogEntryService} from '../../../shared/bundles/app/blog_entry/blog_entry.service';
import {UrlService} from '../../../services/urlService';
import {BaseComponent} from '../../base.component';
import {CMSCMSElementService} from '../../../shared/bundles/cms/cms_element/cms_element.service';
import {PaginationComponent} from '../../../core/pagination/pagination.component';
import {AppVariables} from '../../../config/app.variables';

@Component({
  selector: 'bundle.blog-entries-list',
  templateUrl: './bundle.blog.entries.list.component.html',
  styleUrls: ['./bundle.blog.entries.list.component.scss'],
  providers: []
})

export class BundleBlogEntriesListComponent extends BaseComponent implements OnInit{

    protected blogService;

    @ViewChild('pagination') pagination: PaginationComponent;

    ngOnInit() {
      this.blogService = this._injector.get(AppBlogEntryService);

      this.urlService.urlParams.subscribe((params:any) => {

        let paramsRequest = this.urlService.parseParamsToFetch(params, {'itemsPerPage': AppVariables.pagination.default});
        this.blogService.getAll(paramsRequest, true).subscribe(data => {
          this._data = data['hydra:member'];
          this.pagination.totalElements = data['hydra:totalItems'];

        });

      });


    }
}
