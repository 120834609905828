
<ng-container *ngIf="data">

<div class="list">
  <div class="flexbox">
    <ng-container *ngFor="let opinion of data">
      <div class="image-container">
        <app-core-image [url]="opinion.photo"></app-core-image>
      </div>
      <div class="signature">{{opinion.signature}}</div>
      <div class="article-content">{{opinion.content}}</div>
    </ng-container>
  </div>
</div>


</ng-container>
