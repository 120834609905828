import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";


@Component({
    selector: 'bundle.user-invoices',
    templateUrl: './user.invoices.component.html',
    styleUrls: ['./user.invoices.component.scss'],
})

export class UserInvoicesComponent extends BaseComponent implements OnInit{


  ngOnInit() {
    this.apiService.getByStaticUrl(ApiLink('/user-invoices')).subscribe(invoices => {
      console.log('invoices',invoices);
      this.data = invoices;
    })
  }

}
