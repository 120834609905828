import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';
import {UrlService} from "../services/urlService";
import {link} from "../utils/utils";

@Pipe({
  name: 'link',
  pure: false
})

export class LinkPipe implements PipeTransform {

  constructor(protected urlService: UrlService) {}

  transform(value: string, slug: string = "", cmsid: string = ''): string {
    return link(value, slug, cmsid);
    // let url = AppConfig.structurePageList.find(el => el.id == value)?.url;
    // // console.log(value, url, AppConfig.structurePageList)
    // let linkSlug = (slug ? slug + "," : "") + (cmsid && slug ? cmsid : "");
    // let retval = `${url}${linkSlug ? "," + linkSlug : ""}`;
    // return retval.trim();
  }
}
