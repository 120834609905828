<div class="footer-nav">
  <ng-container *ngIf="_data && _data.length > 0">
    <div class="title">{{_data[0]?.header}}</div>
    <ul>
    <ng-container *ngFor="let element of _data">
      <li>
      <a [routerLink]="element.url|href">{{element.title}}</a>
      </li>
    </ng-container>
    </ul>
  </ng-container>
</div>
