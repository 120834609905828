import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {FormFormService} from "../../../../services/form.api.service";
import {DatePipe} from "@angular/common";
import {ApiService} from "../../../../services/api.service";
import {UrlService} from "../../../../services/urlService";
import {ConverterService} from "../../../../services/converter.service";
import {AppService} from "../../../../core/services/app.service";


@Component({
    selector: 'bundle.user-workinghours-recruiter',
    templateUrl: './user.workinghours.recruiter.component.html',
    styleUrls: ['./user.workinghours.recruiter.component.scss'],
    providers: [DatePipe]

})

export class UserWorkinghoursRecruiterComponent extends BaseComponent implements OnInit{

  message
  date = new Date()

  @Input() me;

  private datePipe

  ngOnInit() {
    this.datePipe = this._injector.get(DatePipe);
    // this.apiService.getByStaticUrl(ApiLink(`/user-working-hours?date=${this.datePipe.transform(date, 'yyyy-MM-dd')}`)).subscribe(data => {
    //   console.log('working-hours dqwdqwd',data);
    //   this.data = data;
      // console.log(this.groupByWorker(data))
      // this.data = this.groupByWorker(data);
    // });
    this.getWorkingHours();
  }

  getWorkingHours(date = this.date) {
    this.apiService.getByStaticUrl(ApiLink(`/user-working-hours?date=${this.datePipe.transform(date, 'yyyy-MM-dd')}`)).subscribe(data => {
      // this.data = data;
      this.data = this.groupByWorker(data);

    });

  }

  changeMonth(date) {
    this.date = date;
    // @ts-ignore
    // this.statsComponent.date = date;
    this.getWorkingHours()
  }

  groupByWorker(data) {
    return data
      .map(el => el.workingUser.id)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(id => data.find(el => el.workingUser.id === id).workingUser)
      .map(workerUser => {
        let obj = {}
        obj['user'] = workerUser;
        obj['data'] = data.filter(el => el.workingUser.id === workerUser.id)
        return obj;
      })
  }

  dateDiff(date:string, date1:string) {
    let d = new Date(date);
    let d1 = new Date(date1);
    let minutes = (d1.getTime() - d.getTime()) / (1000 * 60);
    let minDisplay = minutes%60 < 10 ? '0' + minutes%60 : minutes%60;
    return `${Math.floor(minutes/60)}:${minDisplay}h`
  }


}
