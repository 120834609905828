import {CoreLanguage} from '../shared/bundles/core/language/language.model';
import {AppConfig} from '../config/app.config';
import {CustomSelectOptionModel} from '../bundles/form/customselect/customSelectOptionModel';

export class Languages {

  protected languages: CoreLanguage[] = [];

  constructor(languagesArr: CoreLanguage[]) {
    this.languages = languagesArr;
  }

  public getDefault(): CoreLanguage {
    return this.languages.find(el => el.isDefault);
  }

  public isDefault(shortName:string): boolean {
    return !!this.languages.find(el => el.shortName === shortName)?.isDefault || false;
  }

  public isCurrentLangDefault(): boolean {
    return AppConfig.language === this.getDefault()?.shortName || false;
  }

  public isLang(shortName: string): boolean {
    return !!this.languages.find(el => el.shortName === shortName);
  }

  public getByShortName(shortName: string): CoreLanguage {
    return this.languages.find(el => el.shortName === shortName)
  }

  public getById(id): CoreLanguage {
    return this.languages.find(el => el.id == id);
  }

  public getForSelect(){
    return this.languages.map(el => new CustomSelectOptionModel(el.id, el.shortName))
  }

}
