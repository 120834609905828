import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from "../../base.component";

@Component({
  selector: 'bundle.course-card',
  templateUrl: './bundle.course.card.component.html',
  styleUrls: ['./bundle.course.card.component.scss'],
  providers: []
})

export class BundleCourseCardComponent extends BaseComponent implements OnInit{

    // _element;

    // @Input()
    // set element(data){
    //   this._element = data;
    // }
    // constructor(
    //   protected converter: ConverterService,
    //   protected apiService: ApiService
    // ) {
    //   super();
    // }

    ngOnInit(): void {}
}
