<a class="element" *ngIf="data" [href]="'page_id_blog_view'|trans|link:data.slug:data.cmsid">
  <app-core-image [url]="data?.photo"></app-core-image>
  <div class="category" [innerHTML]="data?.category?.categoryName"></div>
  <h4>{{data?.title}}</h4>
  <div class="article-content" [innerHTML]="data?.precontent|safeHtml"></div>
  <small class="bold tags">
    <ng-container *ngFor="let tag of data.tags">
      <span class="tag">#{{tag.tag}}</span>
    </ng-container>
  </small>
</a>
