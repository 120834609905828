<div class="user-monthly">

  <div class="article-content">
    <table *ngIf="stats">
      <thead>
      <tr>
        <th>{{'user_monthly_summary_table_offer'|trans}}</th>
        <th>{{'user_monthly_summary_table_time'|trans}}</th>
        <th>{{'user_monthly_summary_table_approved_time'|trans}}</th>
        <th>{{'user_monthly_summary_table_worker'|trans}}</th>
        <ng-container *ngIf="canSeeRecruiter()">
          <th >{{'user_monthly_summary_table_recruiter'|trans}}</th>
        </ng-container>
        <ng-container *ngIf="canSeeEmployer()">
          <th >{{'user_monthly_summary_table_employer'|trans}}</th>
        </ng-container>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="objLen(stats) === 0;else list">
        <tr>
<!--          [colSpan]="{'6': canSeeEmployer(), '5': canSeeRecruiter()}"-->
          <td [attr.colspan]="colspan" >{{'user_monthly_summary_empty_list'|trans}}</td>
        </tr>
      </ng-container>
      <ng-template #list>
        <ng-container *ngFor="let element of stats|keyvalue">
          <tr>
            <td><a [href]="'page_id_offer'|trans|link:getOfferById(element.key)?.slug:getOfferById(element.key)?.cmsid">{{getOfferById(element.key)?.title}}</a></td>

            <td>{{dateDiffFromSecond(element.value.h)}}</td>
            <td>{{dateDiffFromSecond(element.value.hround)}}</td>
            <td class="text-right">{{element.value.worker|price}} &euro;</td>
            <ng-container *ngIf="canSeeRecruiter()">
              <td class="text-right">{{element.value.recruiter|price}} &euro;</td>
            </ng-container>
            <ng-container *ngIf="canSeeEmployer()">
              <td class="text-right" >{{element.value.employer|price}} &euro;</td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-template>
      </tbody>
    </table>


  </div>


</div>
