import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from '../../base.component';
import {CMSBoxService} from '../../../shared/bundles/cms/box/box.service';

@Component({
  selector: 'bundle.box-blue',
  templateUrl: './bundle.box.blue.component.html',
  styleUrls: ['./bundle.box.blue.component.scss'],
  providers: []
})
export class BundleBoxBlueComponent extends BaseComponent implements OnInit{

    protected boxService
    protected _boxId;

    constructor(protected _injector: Injector) {
      super(_injector);
      this.boxService = _injector.get(CMSBoxService);
    }

    ngOnInit() {
      // this.boxService = this._injector.get(CMSBoxService)
    }

    @Input()
    set boxId(boxId){
      if (!isNaN(parseInt(boxId)) && this._boxId != boxId) {
        this._boxId = boxId;
        this.boxService.getOne(boxId).subscribe(data => {
          this._data = this.convert(data);
        })
      }
    }
}
