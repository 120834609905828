import {ComponentFactoryResolver, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {throwIfAlreadyLoaded} from './module-import-guard';
import { RouterModule } from '@angular/router';
import {BaseModule} from '../services/base-module';
// import {BundleBlogListComponent} from './list/blog/bundle.blog.list.component';
// import {BundleBlogMenuComponent} from './list/blogMenu/bundle.blog.menu.component';


import {ContentsectionComponent} from '../core/contentsection/contentsection.component';
import {BreadcrumbsComponent} from '../core/breadcrumbs/breadcrumbs.component';
import {LanguagesComponent} from '../core/languages/languages.component';
import {CookiepopupComponent} from '../core/cookiepopup/cookiepopup.component';
import {FooterComponent} from '../core/footer/footer.component';
import {HeaderComponent} from '../core/header/header.component';
import {NavComponent} from '../core/nav/nav.component';
import {SvgComponent} from '../core/svg/svg.component';
import {DynamicSelectorComponent} from '../services/dynamic.selector.component';
import {ImageComponent} from '../core/image/image.component';
import {TransPipe} from '../pipes/trans.pipe';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {CoreSafeHtmlPipe} from '../pipes/core-safe-html.pipe';
import {BundleNewsletterFooterComponent} from './newsletter/newsletter.footer/bundle.newsletter.footer.component';

import {BundleListCenterComponent} from './list/list-center/bundle.list-center.component';
import {BundleRecruitmentDetailComponent} from './recruitment/card/bundle.recruitment-detail.component';
import {BundleRecruitmentListComponent} from './recruitment/recruitment.list/bundle.recruitment.list.component';
import {BundleBlogCardComponent} from './blog/card/bundle.blog.card.component';
import {BundleBlogEntriesListComponent} from './blog/blog.entries.list/bundle.blog.entries.list.component';
import {BundleBlogCategoriesComponent} from './blog/blog.categories/bundle.blog.categories.component';
import {BundleBoxBlueComponent} from './box/box.blue/bundle.box.blue.component';
import {BundleCategoryNavComponent} from './nav/category-nav/bundle.category.nav.component';
import {BundleOffertsListComponent} from './offerts/offerts.list/bundle.offerts.list.component';
import {BundleOffertCardComponent} from './offerts/card/bundle.offert.card.component';
import {BundleOffertsCategoriesComponent} from './offerts/offerts.categories/bundle.offerts.categories.component';
import {BundleFormFormComponent} from './form/form/bundle.form.form.component';
import {BundleFormCustomselectComponent} from './form/customselect/bundle.form.customselect.component';
import {BundleCoursesCategoriesComponent} from './courses/courses.categories/bundle.courses.categories.component';
import {BundleCoursesListComponent} from './courses/courses.list/bundle.courses.list.component';
import {BundleCourseCardComponent} from './courses/card/bundle.course.card.component';
import {BundleLatestEventsComponent} from './components/latest.events/bundle.latest.events.component';
import {BundleFooterNavComponent} from './nav/footer-nav/bundle.footer.nav.component';
import {BundleFooterOwnerComponent} from './components/footer.owner/bundle.footer.owner.component';
import {BundleListComponent} from './list/list/bundle.list.component';
import {BoxCustomSectionComponent} from './components/custom.section/bundle.custom.section.component';
import {HrefPipe} from '../pipes/href.pipe';
import {IsUrlVisitPipe} from '../pipes/isUrlVisit.pipe';
// import {BoxMainPageGreenSectionComponent} from './components/main-page-green-section/bundle.main-page-green-section.component';
// import {BundleBoxImageComponent} from './box/image/bundle.box-image.component';
import {EnumeratePipe} from '../pipes/enumarte.pipe';
import {PaginationComponent} from '../core/pagination/pagination.component';
import {BundleBoxComponent} from './box/box/bundle.box.component';
import {UserLoginFormComponent} from "./user/login/user.loginForm.component";
import {BundleArticleComponent} from "./article/article/bundle.article.component";
import {CardComponent} from "./components/product-card/card.component";
import {BundleCardComponent} from "./card/bundle.card.component";
import {BundleFaqComponent} from "./faq/faq/bundle.faq.component";
import {MessageComponent} from "../core/message/message.component";
import {BundleFaqCardComponent} from "./faq/faq-card/faq.card.component";
import {BundleFaqCategoriesComponent} from "./faq/faq.categories/bundle.faq.categories.component";
import {NotfoundComponent} from "../core/notfound/notfound.component";
import {LinkPipe} from "../pipes/link.pipe";
import {BundleBlogArticleComponent} from "./blog/blog.article/bundle.blog.article.component";
import {BundleOpinionsListComponent} from "./opinions/opinions.list/bundle.opinions.list.component";
import {UserJobOfferComponent} from "./user/pages/JobOffers/user.job.offer.component";
import {UserConsultationComponent} from "./user/pages/consultation/user.consultation.component";
import {AppModule} from "../app.module";
import {DatetimepickerDirective} from "../directives/datetimepicker.directive";
import {DatepickerDirective} from "../directives/datepicker.directive";
import {BundleOffertsArticleComponent} from "./offerts/offerts.article/bundle.offerts.article.component";
import {UserSubscriptionComponent} from "./user/pages/subscription/user.subscription.component";
import {UserAccountComponent} from "./user/account/user.account.component";
import {UserProfileComponent} from "./user/pages/profile/user.profile.component";
import {BundleCoursesArticleComponent} from "./courses/courses.article/bundle.courses.article.component";
import {UserCoursesComponent} from "./user/pages/courses/user.courses.component";
import {UserWorkinghoursComponent} from "./user/pages/workinghours/user.workinghours.component";
import {UserJobOfferRecruiterComponent} from "./user/pages/jobOfferRecruiter/user.job.offer.recruiter.component";
import {UserNewUserComponent} from "./user/pages/newUser/user.new-user.component";
import {UserWorkinghoursRecruiterComponent} from "./user/pages/workinghours-recruiter/user.workinghours.recruiter.component";
import {BundleNewsletterActivateComponent} from "./newsletter/newsletter.activate/bundle.newsletter.activate.component";
import {UserInvoicesComponent} from "./user/pages/invoices/user.invoices.component";
import {UserWorkinghoursEmployerComponent} from "./user/pages/workinghours-employer/user.workinghours.employer.component";
import {BundleDateChangerComponent} from "./components/date-changer/bundle.date.changer.component";
import {BundleUserMonthlyComponent} from "./components/user-monthly/bundle.user.monthly.component";
import {UserWorkinghoursEmployerNotApprovedComponent} from "./user/pages/workinghours-employer-not-approved/user.workinghours.employer.not.approved.component";
import {UserAfterPayComponent} from "./user/pages/after-pay/user.after.pay.component";
import {UserRememberComponent} from "./user/remember/user.remember.component";
import {PricePipe} from "../pipes/price.pipe";
import {IsSvgPipe} from "../pipes/isSvg.pipe";
import {IsImgPipe} from "../pipes/isImg.pipe";
import {MediaComponent} from "../core/media/media.component";


const components = [

  // BundleBlogListComponent,
  // BundleBlogMenuComponent,
  BundleNewsletterFooterComponent,
  BundleBoxComponent,

  BundleRecruitmentListComponent,
  BundleListCenterComponent,
  BundleRecruitmentDetailComponent,
  BundleBlogCardComponent,
  BundleBlogEntriesListComponent,
  BundleBlogCategoriesComponent,
  BundleBlogArticleComponent,
  BundleBoxBlueComponent,
  BundleCategoryNavComponent,
  BundleOffertsListComponent,
  BundleOffertCardComponent,
  BundleOffertsCategoriesComponent,
  BundleFormFormComponent,
  BundleFormCustomselectComponent,
  UserLoginFormComponent,
  BundleArticleComponent,
  BundleFaqComponent,

  BundleOpinionsListComponent,
  BundleCoursesArticleComponent,
  BundleCardComponent,
  BundleCoursesCategoriesComponent,
  BundleCoursesListComponent,
  BundleCourseCardComponent,

  BundleLatestEventsComponent,
  BundleFooterNavComponent,
  BundleFooterOwnerComponent,
  BundleListComponent,

  BoxCustomSectionComponent,
  BundleFaqCardComponent,
  UserProfileComponent,
  UserAccountComponent,
  BundleFaqCategoriesComponent,
  UserJobOfferComponent,
  UserConsultationComponent,
  UserSubscriptionComponent,
  UserCoursesComponent,
  UserWorkinghoursComponent,
  UserJobOfferRecruiterComponent,
  UserNewUserComponent,
  BundleOffertsArticleComponent,
  UserWorkinghoursRecruiterComponent,
  BundleNewsletterActivateComponent,
  UserInvoicesComponent,
  UserWorkinghoursEmployerComponent,
  BundleDateChangerComponent,
  BundleUserMonthlyComponent,
  UserWorkinghoursEmployerNotApprovedComponent,
  UserAfterPayComponent,
  UserRememberComponent,

  MediaComponent
  // BoxMainPageGreenSectionComponent,
  // BundleBoxImageComponent,
];

const coreComponents = [
    ContentsectionComponent,
    BreadcrumbsComponent,
    CookiepopupComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    SvgComponent,
    LanguagesComponent,
    DynamicSelectorComponent,
    ImageComponent,
    PaginationComponent,
    MessageComponent,
    NotfoundComponent,

  // DatetimepickerDirective,


];

const directives = [
  DatetimepickerDirective,
  DatepickerDirective
]

const pipes = [
  TransPipe,
  SafeHtmlPipe,
  CoreSafeHtmlPipe,
  HrefPipe,
  IsUrlVisitPipe,
  EnumeratePipe,
  LinkPipe,
  PricePipe,
  IsSvgPipe,
  IsImgPipe
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    // BrowserModule,
  ],
    exports: [...components, ...coreComponents, ...pipes, ...directives],
    declarations: [...components, ...coreComponents, ...pipes, ...directives],
    providers: []
})

export class BundlesModule extends BaseModule{

  dynamicComponents = [...components, ...coreComponents]

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  //   constructor(@Optional() @SkipSelf() parentModule: BundlesModule) {
  //      throwIfAlreadyLoaded(parentModule, 'BundlesModule');
  //   }
}
