import {Component, Injector, Input, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {AppJobOfferService} from '../../../shared/bundles/app/job_offer/job_offer.service';
import {BaseComponent} from '../../base.component';
import {PaginationComponent} from "../../../core/pagination/pagination.component";
import {AppVariables} from "../../../config/app.variables";

@Component({
  selector: 'bundle.offerts-list',
  templateUrl: './bundle.offerts.list.component.html',
  styleUrls: ['./bundle.offerts.list.component.scss'],
  providers: []
})

export class BundleOffertsListComponent extends BaseComponent implements OnInit{

    protected jobService: AppJobOfferService;

    @ViewChild('pagination') pagination: PaginationComponent;

    ngOnInit(): void {
      this.jobService = this._injector.get(AppJobOfferService);

      this.urlService.urlParams.subscribe((params:any) => {

        let paramsRequest = this.urlService.parseParamsToFetch(params, {'itemsPerPage': AppVariables.pagination.default});
        this.jobService.getAll(paramsRequest, true).subscribe(data => {
          this._data = data['hydra:member'];
          this.pagination.totalElements = data['hydra:totalItems'];
        });

      });

    }

}
