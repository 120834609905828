import {Component, Input, OnInit, Output, EventEmitter, Injector} from '@angular/core';
import {UrlService} from '../../../services/urlService';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from '../../base.component';
import FooterNav from "./bundle.footer.nav.interface";

@Component({
  selector: 'bundle.footer-nav',
  templateUrl: './bundle.footer.nav.component.html',
  styleUrls: ['./bundle.footer.nav.component.scss'],
  providers: []
})

export class BundleFooterNavComponent extends BaseComponent implements OnInit{

    ngOnInit():void {}

    public _data: FooterNav[];

    protected onSetData(data: any = this._data) {
        super.onSetData(data);
    }
}
