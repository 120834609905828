import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppConfig} from '../config/app.config';
import {AppService} from '../core/services/app.service';
import {ApiLink} from "../utils/utils";

@Injectable({ providedIn: 'root' })
export class StructurePagesResolver implements Resolve<any> {
  private cache = {};

  constructor(private http: HttpClient, private appService: AppService) {}

  async resolve() {
    if(Object.keys(this.cache).length > 0){
      return this.cache
    }
    this.cache = await this.http.get(ApiLink('/cms/pages'), this.appService.getOptions()).toPromise();
    return this.cache
  }
}
