import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {AppSubscriptionService} from "../../../../shared/bundles/app/subscription/subscription.service";
import {Form} from "../../../../services/form.service";


@Component({
    selector: 'bundle.user-subscription',
    templateUrl: './user.subscription.component.html',
    styleUrls: ['./user.subscription.component.scss'],
})

export class UserSubscriptionComponent extends BaseComponent implements OnInit{

  // applicationService

  subscriptions = [];

  message;
  messageMySubs;

  ngOnInit() {
    this.getMySubs();
    this._injector.get(AppSubscriptionService).getAll().subscribe(subs => {
      console.log(subs);
      this.subscriptions = subs;
    })
  }

  getMySubs() {
    this.apiService.getByStaticUrl(ApiLink('/user-subscriptions')).subscribe(data => {
      console.log('subscitions',data);
      this.data = data;
    })
  }

  addSub(id) {
    let fd = new FormData();
    fd.append('subscription', id);
    this.apiService.staticPost(ApiLink('/create-subscription'), fd).subscribe(response => {
      console.log(response);
      this.message = response;
      this.getMySubs();
      setTimeout(() => {
        this.message = null;
      }, 6000);
    })
  }

  delete(id) {
    let fd = new FormData();
    fd.append('id', id);
    this.apiService.staticPost(ApiLink('/delete-subscription'), fd).subscribe(response => {
      console.log(response);
      this.messageMySubs = response;
      this.getMySubs();
      setTimeout(() => {
        this.messageMySubs = null;
      }, 6000);
    })

  }

  getSuffixForDuration(durationDays) {
    return durationDays === 1 ? 'subscription_duration_day' : 'subscription_duration_days'
  }

}
