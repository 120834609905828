import {Component, Injector, Input} from '@angular/core';
import {BaseComponent} from '../base.component';
import {CMSBoxService} from '../../shared/bundles/cms/box/box.service';

@Component({
  selector: 'app-box-component',
  template: '',
})
export abstract class AbstractBoxComponent extends BaseComponent{

  protected boxService

  constructor(protected _injector: Injector) {
    super(_injector);
    this.boxService = _injector.get(CMSBoxService);
  }

  @Input()
  set boxId(boxId){
    if (!isNaN(parseInt(boxId))) {
      this.boxService.getOne(boxId).subscribe(data => {
        this._data = this.convert(data);
      })
    }
  }

}
