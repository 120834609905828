<!--<div class="wrapper">-->

<!--  <bundle class="list-center" [elements]="data" [elementSelektor]="'recruitment-detail'"></bundle>-->

<!--</div>-->


<div class="list-center">
  <div class="flexbox">
    <ng-container *ngIf="data && data.length > 0">
      <ng-container *ngFor="let element of data; let i = index">
        <bundle class="recruitment-detail" [data]="element" [sortPosition]="i"></bundle>
      </ng-container>
    </ng-container>
  </div>
</div>



