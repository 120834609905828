<header>
  <div class="desktop">
    <div class="wrapper logobar flexbox ">
<!--      <div class="logo_text">-->
        <a routerLink="{{'/'|href}}" class="logo" *ngIf="owner">
<!--          <app-core-image [width]="1280" [height]="800" [quality]="90" [method]="'resize'"-->
<!--                          [url]="'/assets/staticresource/project/logo.png'"></app-core-image>-->
          <app-core-image [width]="400" [height]="100" [quality]="90" [method]="'resize'"
                          [url]="owner?.logo"></app-core-image>
        </a>
<!--        <div>{{'logo_text'|trans}}</div>-->
<!--      </div>-->
      <div class="flexbox">
        <app-core-nav [forcedElementId]="1" [menu]="menu"></app-core-nav>
        <div class="flexbox additional-buttons">
            <div class="acc-btns-container">
              <ng-container *ngIf="appService.checkCredentials(); else notLogged">


              <a class="button" [href]="'page_id_my_account'|trans|link">{{'btn_my_account'|trans}}</a>
              <div class="button-link" (click)="appService.logout()">{{'btn_logout'|trans}}</div>

              </ng-container>
              <ng-template #notLogged>
                <div class="button" [routerLink]="'page_id_my_account'|trans|link">{{'btn_my_account'|trans}}</div>
              </ng-template>
            </div>
          <core-languages></core-languages>
<!--          <div class="button-outline-green">pl</div>-->
        </div>
      </div>
    </div>
  </div>

  <div class="mobiles">
        <div class="topbar">
          <core-languages></core-languages>
          <div class="icon menuicon" (click)="toggleMobileMenu()">
                <app-core-svg [url]="'/assets/staticresource/layout/base/menu.svg'"></app-core-svg>
                <span></span>
                <span></span>
                <span></span>
            </div>
          <div class="logo-container">
<!--            <a routerLink="{{'/'|href}}" (click)="toggleMobileMenu()" class="logo" *ngIf="owner?.logo">-->
<!--              <app-core-image *ngIf="owner?.logo" [width]="260" [height]="80" [quality]="100" [method]="'resize'" [url]="owner?.logo"></app-core-image>-->
<!--            </a>-->
            <a routerLink="{{'/'|href}}" class="logo" *ngIf="owner">
<!--              <app-core-image [width]="1280" [height]="800" [quality]="90" [method]="'resize'"-->
<!--                              [url]="'/assets/staticresource/project/logo.png'"></app-core-image>-->
              <app-core-image [width]="220" [height]="60" [quality]="90" [method]="'resize'"
                              [url]="owner?.logo"></app-core-image>
            </a>
<!--            <div>{{'logo_text'|trans}}</div>-->
          </div>
          <div class="icon menuiconclose" (click)="toggleMobileMenu()">
                <app-core-svg [url]="'/assets/staticresource/layout/base/close.svg'"></app-core-svg>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="nav">
          <ul class="topLinks"></ul>
          <app-core-nav [forcedElementId]="1" [recursive]="true"  class="base" [menu]="menu">

            <ng-container *ngIf="appService.checkCredentials(); else notLoggedMobile">
              <li>
                <span class="" [routerLink]="'page_id_my_account'|trans|link">{{'btn_my_account'|trans}}</span>
              </li>

              <li>
                <span class="" (click)="appService.logout()">{{'btn_logout'|trans}}</span>
              </li>

            </ng-container>
            <ng-template #notLoggedMobile>
              <li>
                <span class="" [routerLink]="'page_id_my_account'|trans|link">{{'btn_my_account'|trans}}</span>
              </li>
            </ng-template>
          </app-core-nav>
        </div>
    </div>

</header>
