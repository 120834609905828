// @todo RemoveThisMessage. If the message remains, the file could be regenerated!
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import {map, catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';


import {ApiService} from './api.service';
import {AppConfig} from '../config/app.config';
import {CoreLocale} from '../shared/bundles/core/locale/locale.model';

@Injectable({
  providedIn: 'root'
})
export class LocalesService extends ApiService{

  public getByLang(langShortName: string): Observable<any> {
    if (!this.getAllCache){
      this.request$.emit('starting');
      var url = `${this.apiUrl}/${AppConfig.language}/core/locales?itemsPerPage=1000000`;
      url += `?lang=${langShortName}`;
      return this.http.get(url, this.appService.getOptions()).pipe(
        map(response => {
          this.request$.emit('finished');
          return response;
        }),
        catchError(error => this.handleError(error))
      );
    }

    return this.getAllCache;
  }

}
