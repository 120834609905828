import {AfterContentInit, Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {AppUserFileTypeService} from "../../../../shared/bundles/app/user_file_type/user_file_type.service";
import {CustomSelectOptionModel} from "../../../form/customselect/customSelectOptionModel";
import {ApiLink} from "../../../../utils/utils";
import {CoreMessage} from "../../../../services/CoreMessage";
import {FormFormService} from "../../../../services/form.api.service";


@Component({
    selector: 'bundle.user-profile',
    templateUrl: './user.profile.component.html',
    styleUrls: ['./user.profile.component.scss'],
})

export class UserProfileComponent extends BaseComponent implements OnInit{

  // applicationService

  protected filetypeService;
  protected formService

  public filesTypes = [];
  public files;
  message
  forValidationErrorList

  @Input() me;

  ngOnInit() {
    this.formService = this._injector.get(FormFormService);
    this.filetypeService = this._injector.get(AppUserFileTypeService)
    this.filetypeService.getAll().subscribe(data => {
      this.filesTypes = data.filter(el => el.userProfile)
      console.log(data.filter(el => el.userProfile));
    })


    this.apiService.getByStaticUrl(ApiLink('/user-files')).subscribe(data => {
      console.log('my- /user-files',data);
      this.files = data['data'][0];
      // this.data = data;
    })
    // setTimeout(() => {
    //   console.log('mne',this.me)
    // },3000)
  }


  createFormLink(type: number) {
    return ApiLink(`/upload-file?filetype=${type}`);
  }

  submitFormAction($event,formKey) {
    $event.preventDefault();

    let $form = $event.target;
    let url = $form.getAttribute('action');

    this.apiService.staticPost(url, new FormData($form)).subscribe(response => {
      let result = this.formService.retriveResponse(formKey, response, false, true);
      if (result instanceof CoreMessage) {
        this.message = result;
      } else {
        this.forValidationErrorList = result;
      }
    })


  }

  getFileTypeById(id) {
    return this.filesTypes.find(el => el.id == id);
  }

}
