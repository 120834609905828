import {Injectable} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {AppConfig} from '../config/app.config';
import {AppVariables} from '../config/app.variables';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(protected route: Router) {
  }

  public urlParams = new BehaviorSubject({});

  public url = new Subject();

  protected _url = ""

  protected _urlParams = {};

  protected originalUrl = "";

  /**
   * todo dodać opis
   * @param url
   */
  parseUrlToParamsObj(url) {
    let paramsKeys = ['slug', 'cmsid']
    let retval = {};
    let paramsNotParsed =url.split('/').reverse()[0].split(",")
    url.split('/').reverse()[0].split(",").slice(1,3).forEach((el,index) => {
      if (paramsKeys[index]) {
        retval[paramsKeys[index]] = el;
      }
    });
    let notParsedParamsKeys = paramsNotParsed.slice(3).filter((el,index) => index%2 ==0);
    let notParsedParamsValues = paramsNotParsed.slice(3).filter((el,index) => index%2 !=0);
    notParsedParamsKeys.forEach((el,index) => {
      retval[el] = notParsedParamsValues[index];
    });
    return retval
  }

  /**
   * todo dodać opis
   * @param url
   */
  changeUrl(url:string) {
    this.originalUrl = url;
    let paramsKeys = ['slug', 'cmsid']
    let retval = this.parseUrlToParamsObj(url);
    // let test =  url.split('/').reverse().join("/").split(",")[0]

    let initUrl = this._url;
    let initParams = this.paramsToString(this._urlParams);
    //
    // // console.log('-------------------------------')
    // // console.log('change URL', url);
    // // console.log('(this.paramsToString() != this.paramsToString(retval)', (this.paramsToString() != this.paramsToString(retval)))
    // // console.log('this._urlParams, retval', this.paramsToString(), this.paramsToString(retval));
    // // console.log('this._url != url.split(",")[0]', this._url != url.split(",")[0])
    // // console.log('this._url, url.split(",")[0]', this._url , url.split(",")[0])
    // // console.log('-------------------------------')
    //
    if (this._url != url.split(",")[0]){
      this._url = url.split(",")[0];
    }

    if(this.paramsToString() != this.paramsToString(retval)){
      this._urlParams = retval;
    }

    if (initUrl != url.split(",")[0]){
      this.url.next(this._url);
    }

    if(initParams != this.paramsToString(retval)){
      this.urlParams.next(this._urlParams);
    }
  }

  /**
   * Dodaje parametr slug oraz cmsid, oraz wywołuje subscrib'a
   * @param slugName
   * @param cmsId
   */
  addSlugParameter(slugName:string, cmsId:string|number){
    let copyParams = JSON.parse(JSON.stringify(this._urlParams));
    copyParams['slug'] = slugName;
    copyParams['cmsid'] = cmsId;

    let url = this._url + this.paramsToString(copyParams);
    this.route.navigateByUrl(url, {replaceUrl:true});
    this.changeUrl(url);
  }

  /**
   * Dodaje parametr do url oraz wywołuja subscriba ze zmianami.
   * @param key
   * @param value
   */
  addParametr(key, value) {
    let copyUrlParams = JSON.parse(JSON.stringify(this._urlParams));
    copyUrlParams[key] = value

    let url = this._url + this.paramsToString(copyUrlParams);
    this.route.navigateByUrl(url, {replaceUrl:true});
    this.changeUrl(url);
  }

  /**
   * Usuwa podany parametr i wywołuje subscriba w komponentach.
   * @param key
   */
  removeParametr(key) {
    let copyUrlParams = JSON.parse(JSON.stringify(this._urlParams));
    delete copyUrlParams[key];
    let url = this._url + this.paramsToString(copyUrlParams);
    this.route.navigateByUrl(url, {replaceUrl:true})
    this.changeUrl(url);
  }

  /**
   * Zwraca wszystkie parametry jako poprawny string.
   * @param params
   */
  paramsToString(params = this._urlParams){
    let retval = [];
    if (params['slug']){
      retval.push(params['slug'])
    }
    if (params['cmsid'] != undefined){
      retval.push(params['cmsid'])
    }
    for (let key in params){
      if (key !== "slug" && key !== "cmsid") {
        retval.push(key + ',' +params[key])
      }
    }
    return "," + retval.join(",")
  }

  /**
   * Zwraca parametry aktualnego url jako poprawny string, wykluczając parametry slug i cmsid.
   * @param params
   * @param additionalParams
   */
  parseParamsToFetch(params:any, additionalParams: any = {}) {
    let retval = [];
    for (let key in params){
      if (key !== 'slug' && key !== 'cmsid') {
        retval.push(`${key}=${params[key]}`)
      }
    }
    for (let key in additionalParams){
      retval.push(`${key}=${additionalParams[key]}`)
    }
    return retval.length == 0 ? "" : "?"+retval.join("&");

  }

  /**
   * Zwraca aktualny adres url bez definicji języka strony.
   */
  getUrlWithoutLang(): string {
    console.log(this._url)
    let urlParts = this._url.split("?")[0].split('/').filter(el => el != '');

    if (AppVariables.Languages.isLang(urlParts[0])) {
      return "/" + urlParts.filter((el, index) => index !== 0).join("/")
    }
    console.log('urlParts',urlParts)
    return `/${urlParts.join("/")}`
    // return this._url[0] === "/" ? this._url : `/${this._url}`;
  }

  getLang() {
    let urlParts = this._url.split('/').filter(el => el != '');
    if (AppVariables.Languages.isLang(urlParts[0])) {
      return AppVariables.Languages.getByShortName(urlParts[0])
    }
    return AppVariables.Languages.getDefault();
  }

  get params() {
    return this._urlParams
  }

  getUrl() {
    return this._url;
  }

  redirect(url:string) {
    this.route.navigateByUrl(url, {replaceUrl:true})
  }
}
