<div class="offer-article">
  <ng-container *ngIf="data">

    <h1 class="title">{{data?.title}}</h1>

    <div class="information">
      <div class="date">Dodano: {{data?.createdAt|date:'dd.MM.yyyy'}}</div>
      <div class="flexbox localization">
        <app-core-svg [url]="'/assets/staticresource/project/placeholder.svg'"></app-core-svg>
        <span>{{data?.city.country.countryName}}, {{data?.city.city}}</span>
      </div>
      <div class="flexbox offert_type">
        <app-core-svg [url]="'/assets/staticresource/project/clock.svg'"></app-core-svg>
        <span>{{data?.times?.name}}</span>
      </div>
    </div>

    <div class="content">
      <div class="content article-content" [innerHTML]="data?.precontent"></div>
      <div class="content article-content" [innerHTML]="data?.content"></div>
    </div>


    <ng-container *ngIf="message">
      <app-core-message [message]="message"></app-core-message>
    </ng-container>

    <div>
      <span class="button" (click)="apply()">Aplikuj</span>
    </div>

  </ng-container>
</div>
