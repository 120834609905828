<ng-container *ngIf="data">
  <a class="element card-view" [href]="'page_id_course_view'|trans|link:data.slug:data.cmsid">
    <div class="category">{{data?.category.name}}</div>
    <h4 class="title">{{data.title}}</h4>
    <div class="content article-content" [innerHTML]="data?.precontent"></div>
    <!--  <div class="flexbox details-container">-->
    <div class="flexbox price">
      <app-core-svg [url]="'/assets/staticresource/project/dollar.svg'"></app-core-svg>
      <span>{{data.preprice}}</span>
    </div>
    <!--  </div>-->

    <a href="" class="button-underline-green flexbox">
      <span>Dowiedz się więcej</span>
      <app-core-svg [url]="'/assets/staticresource/project/next.svg'"></app-core-svg>
    </a>
  </a>



</ng-container>
