<div class="offets-list">
<div class="flexbox">
  <ng-container *ngIf="_data">
    <ng-container *ngFor="let element of data; let i = index">
      <bundle class="offert-card" [data]="element"></bundle>
      <ng-container *ngIf="(i+1)%3 === 0">
        <div class="break"></div>
      </ng-container>
    </ng-container>
    <bundle class="offert-card"></bundle>
  </ng-container>
</div>

<bundle class="pagination" #pagination></bundle>
</div>
