import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'coreSafeHtml',
})
export class CoreSafeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(html): any {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

}
