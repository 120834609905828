import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";


@Component({
    selector: 'bundle.user-courses',
    templateUrl: './user.courses.component.html',
    styleUrls: ['./user.courses.component.scss'],
})

export class UserCoursesComponent extends BaseComponent implements OnInit{

  // applicationService

  ngOnInit() {
    this.apiService.getByStaticUrl(ApiLink('/user-courses ')).subscribe(data => {
      this.data = data;
      console.log('courses', data);
    })
  }

  pay(course) {

  }
}
