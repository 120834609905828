<div class="">

  <div class="newsletter-form">
    <app-core-message *ngIf="message !== undefined" [message]="message"></app-core-message>
    <form #formSubscribeNewsletter = "ngForm" (ngSubmit)="submitPost($event ,formSubscribeNewsletter.value)" id="generatedForm_NewsletterFormSubscribe">
<!--      *ngIf="message === undefined">-->

      <div class="container">
        <div>
          <div class="form_component component_input">
            <input type="text" class="input-email" name="email" ngModel placeholder="{{'footer_newsletter_email'|trans}}" id="generatedForm_NewsletterFormSubscribe_field_email">
          </div>
          <div class="input_checkbox">
            <input type="checkbox" name="rodo" value="1" id="generatedForm_NewsletterFormSubscribe_field_rodo">
            <label for="generatedForm_NewsletterFormSubscribe_field_rodo">{{'footer_newsletter_checkbox'|trans}}</label>
          </div>
        </div>
        <div class="form_component component_submit component-submit-desktop">
          <input type="submit" class="button" value="{{'footer_newsletter_submit'|trans}}">
        </div>

        <div class="component_submit component-submit-mobile">
          <input type="submit" class="button newsletter-btn-submit newsletter-footer-submit-mobile" value="{{'footer_newsletter_submit'|trans}}">
        </div>

      </div>
    </form>
  </div>
</div>
