import {Component, Injector, Input, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {CmsElementApiService} from "../../../services/cmsElement.api.service";
import {ApiLink, getLocale, link} from "../../../utils/utils";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'bundle.offerts-article',
  templateUrl: './bundle.offerts.article.component.html',
  styleUrls: ['./bundle.offerts.article.component.scss'],
  providers: []
})

export class BundleOffertsArticleComponent extends BaseComponent implements OnInit{

    protected cmsElementApiService

    message


    ngOnInit(): void {
      this.cmsElementApiService = this._injector.get(CmsElementApiService);
      this.cmsElementApiService.getByCmsId(this.urlService.params['cmsid']).subscribe(data => {
        this.data = data.length === 1 ? data[0] : data;
      })
    }

    apply() {
      if (this.appService.checkCredentials()){
        let fd = new FormData();
        fd.append('job', this.data.id);
        this.apiService.staticPost(ApiLink('/job-application'),fd).subscribe(response => {
          // console.log(response);
          if (response) {
            this.message = response
          }
        })
      }else {
        this.urlService.redirect(link(getLocale('page_id_login')) + `?redirect=${window.location.pathname}`)
      }
    }

}
