<div class="user-courses">
<div class="article-content" *ngIf="data">
  <table>
    <thead>
    <tr>
      <th>{{'user_courses_table_name'|trans}}</th>
      <th>{{'user_courses_table_category'|trans}}</th>
      <th>{{'user_courses_table_join_date'|trans}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngIf="data.length === 0; else coursesTable">
      <tr>
        <td colspan="4">{{'user_courses_table_empty'|trans}}</td>
      </tr>
    </ng-container>

    <ng-template #coursesTable>
      <ng-container *ngFor="let course of data">
      <tr>
        <td><a [href]="'page_id_course_view'|trans|link:course.course.slug:course.course.cmsid">{{course.course.title}}</a></td>
        <td>{{course.course.category.name}}</td>
        <td>{{course.createdAt|date:'dd/MM/YYYY HH:mm'}}</td>
        <td>
          <ng-container *ngIf="course.paid; else notPaid">
            {{'user_courses_course_paid'|trans}}
          </ng-container>
          <ng-template #notPaid>
            <ng-container *ngIf="course.price">
              <a class="button" href="/pay/course/{{course.id}}" >{{'user_courses_btn_pay'|trans}} {{course.price}}&euro;</a>
            </ng-container>
          </ng-template>
        </td>
      </tr>
      </ng-container>
    </ng-template>
    </tbody>
  </table>
</div>

</div>
