import {AppConfig} from './config/app.config';

import {Component} from '@angular/core';
import {CoreLanguageService} from './shared/bundles/core/language/language.service';
import {CoreLanguage} from './shared/bundles/core/language/language.model';
import {CoreLocale} from './shared/bundles/core/locale/locale.model';
import {AppVariables} from './config/app.variables';
import {CoreLocaleService} from './shared/bundles/core/locale/locale.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreDomain} from './shared/bundles/core/domain/domain.model';
import {CoreDomainService} from './shared/bundles/core/domain/domain.service';

import {CoreOwnerData} from './shared/bundles/core/owner_data/owner_data.model';
import {CoreOwnerDataService} from './shared/bundles/core/owner_data/owner_data.service';
import {Languages} from './utils/Languages';
import {CookieService} from "ngx-cookie-service";
// import {SettingsOwnerData} from './shared/bundles/settings/ownerdata/ownerdata.model';
// import {SettingsOwnerDataService} from './shared/bundles/settings/ownerdata/ownerdata.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.scss'
    ],
    providers: [],
})

export class AppComponent{

    constructor(
        private languageService: CoreLanguageService,
        private localeService: CoreLocaleService,
        private router: Router,
        private settingsOwnerDataService: CoreOwnerDataService,
        private coreDomainService: CoreDomainService,
        private route: ActivatedRoute,
        private cookieService: CookieService,
    ) {
        // this.setLanguage();
        this.getOwner();
        this.getDomain();

        // let queryParams = route.snapshot;
        // console.log('queryParams',queryParams)
        // if (queryParams['ref']){
        //   this.cookieService.set('ref', queryParams['ref']);
        //   const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
        //   this.router.navigateByUrl(urlWithoutQueryParams);
        // }

    }

    protected getOwner(): void {
        this.settingsOwnerDataService.getCurrent().subscribe((owner: CoreOwnerData[]) => {
            AppConfig.owner = owner[0];
        });
    }

    protected getDomain(): void {
        this.coreDomainService.getDefault().subscribe((domain: CoreDomain) => {
            AppConfig.domain = domain;
            if (domain.googleAnalyticsCode) {
                this.switchOnGoogleAnalitycs();
            }
            // if (domain.customJsCodeInHeader) {
            //     this.addCustomCode();
            // }
        });
    }

    // protected setLanguage(): void {
    //     var appLanguage = AppConfig.language;
    //     var languageFromUrl = '';
    //
    //     var urlArr = window.location.pathname.split('/');
    //     if (urlArr[1]) {
    //         languageFromUrl = urlArr[1];
    //         AppConfig.language = languageFromUrl;
    //     }
    //
    //     this.languageService.getAll().subscribe((languageList: Array<CoreLanguage>) => {
    //         var selectedLanguageObj = languageList.filter((subject) => subject.shortName === languageFromUrl);
    //
    //
    //         if (selectedLanguageObj.length > 0) {
    //             appLanguage = selectedLanguageObj[0].shortName;
    //             AppConfig.language = appLanguage;
    //             // AppConfig.currency = selectedLanguageObj[0].coreLanguageCurrency;
    //             // this.language = appLanguage;
    //         } else {
    //             var defaultLanguageObj = languageList.filter((subject) => subject.isDefault);
    //
    //             if (defaultLanguageObj.length > 0) {
    //                 appLanguage = defaultLanguageObj[0].shortName;
    //                 this.router.navigate(['/' + appLanguage]);
    //                 AppConfig.language = appLanguage;
    //                 // AppConfig.currency = defaultLanguageObj[0].coreLanguageCurrency;
    //                 // this.language = appLanguage;
    //             }
    //
    //         }
    //
    //         this.setLocales();
    //
    //     });
    // }
    // protected async setLanguage() {
    //   // let lang = AppConfig.language;
    //   let languageFromUrl = null;
    //
    //   // var appLanguage = AppConfig.language;
    //
    //   console.log(window.location)
    //
    //   var urlArr = window.location.pathname.split('/');
    //   if (urlArr[1]) {
    //     languageFromUrl = urlArr[1];
    //     AppConfig.language = languageFromUrl;
    //   }
    //   //np pl lub nic
    //
    //   let languagesList = await this.languageService.getAll().toPromise();
    //   AppVariables.Languages = new Languages(languagesList);
    //
    //   let findByLangFromUrl = languagesList.find((subject) => subject.shortName === languageFromUrl);
    //   let defaultLang = languagesList.find((subject) => subject.isDefault);
    //
    //   if(!findByLangFromUrl){
    //     AppConfig.language = defaultLang.shortName;
    //   }else {
    //     if (languageFromUrl === defaultLang.shortName){
    //       this.router.navigate(['/']);
    //     }
    //     AppConfig.language = findByLangFromUrl.shortName;
    //   }
    //   this.setLocales();


      // this.languageService.getAll().subscribe((languageList: Array<CoreLanguage>) => {
      //   AppVariables.Languages = new Languages(languageList);
      //   console.log('języki: ', languageList)
      //
      //   let findByLangFromUrl = languageList.find((subject) => subject.shortName === languageFromUrl);
      //   let defaultLang = languageList.find((subject) => subject.isDefault);
      //
      //   if(!findByLangFromUrl){
      //     AppConfig.language = defaultLang.shortName;
      //   }else {
      //     if (languageFromUrl === defaultLang.shortName){
      //       this.router.navigate(['/']);
      //     }
      //     AppConfig.language = findByLangFromUrl.shortName;
      //   }
      //
      //
      //   // if(languageFromUrl && selectedLanguageObj) {
      //   //   if (selectedLanguageObj.isDefault){
      //   //     this.router.navigate(['/']);
      //   //   }
      //   // }else {
      //   //   let defaultLanguageObj = languageList.find((subject) => subject.isDefault);
      //   //   if (defaultLanguageObj.shortName !== AppConfig.defaultLanguage) {
      //   //     this.router.navigate(['/' + defaultLanguageObj.shortName]);
      //   //   }
      //   //   AppConfig.language = defaultLanguageObj.shortName;
      //   // }
      //
      //   // if (selectedLanguageObj) {
      //   //   // appLanguage = selectedLanguageObj[0].shortName;
      //   //   AppConfig.language = selectedLanguageObj.shortName;
      //   //   // AppConfig.currency = selectedLanguageObj[0].coreLanguageCurrency;
      //   //   // this.language = appLanguage;
      //   // } else {
      //   //   let defaultLanguageObj = languageList.find((subject) => subject.isDefault);
      //   //
      //   //   if (defaultLanguageObj.length > 0) {
      //   //     appLanguage = defaultLanguageObj[0].shortName;
      //   //     this.router.navigate(['/' + appLanguage]);
      //   //     AppConfig.language = appLanguage;
      //   //     // AppConfig.currency = defaultLanguageObj[0].coreLanguageCurrency;
      //   //     // this.language = appLanguage;
      //   //   }
      //   //
      //   // }
      //
      //   this.setLocales();
      //
      // });
    // }

    // /**
    //  * Metoda ma za zadanie pobranie z api tłumaczeń statycznych i przypisanie ich do obiektu globalnego aby w kazdym
    //  * miejscu programu móc z niego kożystać.
    //  */
    // protected setLocales(): void {
    //     this.localeService.getAll().subscribe((localeList: Array<CoreLocale>) => {
    //         if (localeList.length > 0) {
    //             var result = {};
    //             localeList.forEach(function(value, key): void {
    //                 result[value.objKey] = value.objVal;
    //             }, result);
    //             AppVariables.locale = result;
    //         }
    //     });
    // }

    protected addCustomCode(): void {
        var script = document.createElement('script');
        script.innerHTML = AppConfig.domain.customJsCodeInHeader;
        document.head.appendChild(script);
    }

    protected switchOnGoogleAnalitycs(): void {
        var script = document.createElement('script');
        script.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})
        (window,document,'script','//www.google-analytics.com/analytics.js','ga')
        ;ga('create', '${AppConfig.domain.googleAnalitycsCode}', 'auto')
        ;ga('send', 'pageview');`;

        document.head.appendChild(script);
    }
}
