import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogCategoryService} from '../../../shared/bundles/app/blog_category/blog_category.service';
import {UrlService} from '../../../services/urlService';
import {BaseComponent} from '../../base.component';
import {AppJobOfferTimesBaseService} from '../../../shared/bundles/app/job_offer_times/job_offer_times.base.service';
import {CustomSelectOptionModel} from '../../form/customselect/customSelectOptionModel';
import {CustomSelectSettingsModel} from '../../form/customselect/customSelectSettingsModel';
import {Router} from '@angular/router';
import {AppJobOfferAccommodationService} from '../../../shared/bundles/app/job_offer_accommodation/job_offer_accommodation.service';
import {AppJobOfferAccommodation} from '../../../shared/bundles/app/job_offer_accommodation/job_offer_accommodation.model';
import OffersCategories from './bundle.offers.categories.interface';

@Component({
  selector: 'bundle.offerts-categories',
  templateUrl: './bundle.offerts.categories.component.html',
  styleUrls: ['./bundle.offerts.categories.component.scss'],
  providers: []
})

export class BundleOffertsCategoriesComponent extends BaseComponent implements OnInit{

  protected timesService
  protected accommodationService
  protected router

  public times = [];
  public accommodation = [];
  public customSelectSettings

  public _data: OffersCategories[];

  constructor(
    protected _injector: Injector
  ){
    super(_injector);
    this.timesService = _injector.get(AppJobOfferTimesBaseService);
    this.accommodationService = _injector.get(AppJobOfferAccommodationService);
    this.router = _injector.get(Router);
    this.customSelectSettings = new CustomSelectSettingsModel(true,false);
  }

  ngOnInit() {
    this.timesService.getAll().subscribe(times => {
      let options = times.map(time => new CustomSelectOptionModel(time.id,time.name));
      options.unshift(new CustomSelectOptionModel('0', 'Wybór etatu'))
      this.times = options;
    });
    this.accommodationService.getAll().subscribe((accommodation: AppJobOfferAccommodation[]) => {
      let options = accommodation.map(acc => new CustomSelectOptionModel(acc.id, acc.accommodationName));
      options.unshift(new CustomSelectOptionModel('0', 'Zakwaterowanie'))
      this.accommodation = options;
    });
  }

  changeTime(ev) {
    if (ev == 0){
      this.urlService.removeParametr('times');
    }else {
      this.urlService.addParametr('times', ev);
    }
  }

  changeAcc(ev) {
    if (ev == 0){
      this.urlService.removeParametr('accommodation');
    }else {
      this.urlService.addParametr('accommodation', ev);
    }
  }

  // getTimesSelectOptions() {
  //   return this.times.map(time => new CustomSelectOptionModel(time.name, time.cmsid))
  // }

}
