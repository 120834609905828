import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {Injectable} from '@angular/core';
import {AppService} from '../core/services/app.service';
import {AppConfig} from '../config/app.config';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {ApiLink} from "../utils/utils";


@Injectable({
  providedIn: "root"
})
export class CmsElementApiService extends ApiService{

  protected apiUrl: string;

  getByCmsId(cmsId){
    return this.http.get(ApiLink('/cmsids?cmsid[]=' + cmsId), this.appService.getOptions())
      .pipe(
        map(response => {
          return response;
        })
      )
  }
}
