<div class="user-workinghours">

  <bundle class="date-changer" [format]="'MM.yyyy'" (changeEvent)="changeMonth($event)" #monthPicker></bundle>

  <h3 class="header">{{'working_hours_recruiter_header_summary'|trans}}</h3>
  <bundle class="user-monthly" #statsComponent [date]="date" [me]="me"></bundle>


  <h3 class="header">{{'working_hours_recruiter_header_details'|trans}}</h3>
  <div class="article-content">

    <table *ngIf="data">
      <thead>
      <tr>
        <th>{{'working_hours_employer_table_offer'|trans}}</th>
        <th>{{'working_hours_employer_table_work_time'|trans}}</th>
        <th>{{'working_hours_employer_table_work_time_diff'|trans}}</th>
        <th>{{'working_hours_employer_table_status'|trans}}</th>
        <th>{{'working_hours_employer_table_price_worker'|trans}}</th>
        <th>{{'working_hours_employer_table_price_employer'|trans}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="data.length===0;else list ">
        <tr>
          <td colspan="7">{{'working_hours_employer_empty'|trans}}</td>
        </tr>
      </ng-container>
      <ng-template #list >
        <ng-container *ngFor="let user of data">
          <tr>
            <td colspan="7">{{user.user.email}}({{user.user.name}} {{user.user.surname}})</td>
          </tr>
          <ng-container *ngFor="let job of user.data">
            <tr>
              <td>
                <a class="nobr" target="_blank" [href]="'page_id_offer'|trans|link:job.jobOffer.slug:job.jobOffer.cmsid">{{job.jobOffer.title}}</a>
                <div class="nobr">{{job.jobOffer.city.country.countryName}} - {{job.jobOffer.city.city}}</div>
              </td>
              <td>
                <div class="time">
                  <div class="nobr"><b>{{job.timeStart|date:'dd-MM-YYYY HH:mm'}}</b></div>
                  <div class="nobr"><b>{{job.timeEnd|date:'dd-MM-YYYY HH:mm'}}</b></div>
                </div>
              </td>
              <td>{{dateDiff(job.timeStart, job.timeEnd)}}</td>
              <td>
                <ng-container *ngIf="job.approved; else notApproved"><span class="approved">{{'working_hours_employer_status_approved'|trans}}</span></ng-container>
                <ng-template #notApproved>
                  <span class="not-approved">{{'working_hours_employer_status_not_approved'|trans}}</span>
                </ng-template>
              </td>
              <td class="text-right">{{job.priceWorker|price}} &euro;</td>
              <td class="text-right">{{job.priceEmployer|price}} &euro;</td>
              <td style="padding: 6px">
                <ng-container *ngIf="!job.approved;">
                  <div class="btn-container">
                    <span class="button-link" (click)="accept(job.id, $event)">{{'user_working_hours_employer_not_approved_btn_accept'|trans}}</span>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-template>
      </tbody>
    </table>

  </div>

</div>
