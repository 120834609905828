<div class="user-profile">


  <div class="content"></div>

  <div class="form-blue">
    <bundle class="form" [formId]="11" [urlSend]="'/update-user'" [forcedData]="me"></bundle>
  </div>

  <div class="article-content">
    <table *ngIf="files" class="table-files">
      <thead>
      <tr>
        <th class="text-left">{{'user_profile_table_file_type'|trans}}</th>
        <th class="text-left">{{'user_profile_table_files'|trans}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="files.length === 0; else list">
        <tr>
          <td colspan="2">{{'user_profile_table_empty'|trans}}</td>
        </tr>
      </ng-container>
      <ng-template #list>
      <ng-container *ngFor="let element of files |keyvalue">
        <tr>
          <td>{{getFileTypeById(element.key)?.filedesc}}</td>
          <td>
            <ng-container *ngFor="let file of element.value.files">
              <a target="_blank"  [href]="file.url"  download>{{file.filename}}</a>
            </ng-container>
          </td>
        </tr>
      </ng-container>
      </ng-template>
      </tbody>
    </table>
  </div>


  <div class="forms">
    <ng-container *ngFor="let type of filesTypes">
    <div class="form-blue">
      <form action="{{createFormLink(type.id)}}" method="post" (ngSubmit)="submitFormAction($event, type.id)">
        <div class="description"> {{type.filedesc}}</div>

        <div class="formSections">
          <div class="formGroup">
            <div class="flexbox">
              <div class="form_component component_file">
                <label for="generatedForm_{{type.id}}_field_file">{{'user_profie_form_file_label'|trans}}</label>
                <input type="file" id="generatedForm_{{type.id}}_field_file" name="file" required="true">
              </div>
            </div>
          </div>
        </div>
        <div class="formSections">
          <div class="formGroup">
            <div class="flexbox">
              <div class="form_component component-submit">
                <input type="submit" class="button" value="Wyślij">
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
    <div class="break"></div>
  </div>

</div>


