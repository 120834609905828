import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.faq-categories',
  templateUrl: './bundle.faq.categories.component.html',
  styleUrls: ['./bundle.faq.categories.component.scss'],
  providers: []
})

export class BundleFaqCategoriesComponent extends BaseComponent {

  protected onSetData(data: any = this._data) {

    console.log('faq.categories', data);
    super.onSetData(data);
  }

}
