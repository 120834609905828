export var convertToHtmlLink = function(inputText: string): string {
  var outputText = inputText;
  var regExp = /{{([^}}]+)\}}/g;
  var matches = inputText.match(regExp);

  if (matches) {
    for (var i = 0; i < matches.length; i++) {
      var pattern = matches[i];
      var resultString = '';
      resultString = convertToPageLink(pattern, true);
      outputText = outputText.replace(pattern, resultString);
    }
  }

  return outputText;
};

export var convertToPageLink = function(inputText: string, withTag = false): string {
  var outputText = inputText;
  var label = '';

  if (withTag) {
    outputText = '<a href="';
  }

  var pageIdMatches = inputText.match(/page_id=(.*)name=/);
  if (!pageIdMatches) {
    pageIdMatches = (inputText.match(/page_id=(.*)}}/));
  }

  var url = '';
  if (pageIdMatches && pageIdMatches[1]) {
    url = this.getUrl('', pageIdMatches[1].trim());
  }

  var nameMatches = inputText.match(/name=(.*)}}/);
  if (nameMatches && nameMatches[1]) {
    label = nameMatches[1].trim();
  } else {
    label = url;
  }

  if (withTag) {
    outputText += url + '">' + label + '</a>';
  } else {
    outputText += label;
  }

  return outputText;
};

// funkcje pomocnicze dla fomularzy
// TODO - rozwinąć o kolejne typy kontrolek
export var getFormData = function(formKey, fieldList): any {
  var result = {};
  fieldList.forEach(function(el, ind): void {
    var fieldFullKey = formKey + '_field_' + el.fieldKey;
    var value = null;
    if (el.fieldType === 'input' || el.fieldType === 'select') {
      value = (<HTMLInputElement>document.getElementById(fieldFullKey)).value;
    }
    if (el.fieldType === 'radio' || el.fieldType === 'checkbox') {
      value = (<HTMLInputElement>document.getElementById(fieldFullKey)).checked;
    }
    result[el.fieldKey] = value;
  });
  return result;
};

//TODO - rozwinąć o kolejne typy kontrolek
export var setFormData = function(formKey, fieldList, data): void {
  fieldList.forEach(function(el, ind): void {
    var fieldFullKey = formKey + '_field_' + el.fieldKey;
    var value = data[el.fieldKey];
    if (el.fieldType === 'input' || el.fieldType === 'select') {
      (<HTMLInputElement>document.getElementById(fieldFullKey)).value = value;
    }
    if (el.fieldType === 'radio' || el.fieldType === 'checkbox') {
      (<HTMLInputElement>document.getElementById(fieldFullKey)).checked = value;
    }
  });
};
