import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from "../../base.component";

@Component({
  selector: 'bundle.recruitment-detail',
  templateUrl: './bundle.recruitment-detail.component.html',
  styleUrls: ['./bundle.recruitment-detail.component.scss'],
  providers: []
})

export class BundleRecruitmentDetailComponent extends BaseComponent implements OnInit{
    public _sortPosition: number = 1;

    @Input()
    set sortPosition(sortPosition){
      if (sortPosition != undefined) {
        this._sortPosition = sortPosition;
      }
    }

    ngOnInit(): void {
      // this.apiService.getByStaticUrl(this._slot.dataUrl).subscribe(data => {
      //   this.data = this.converter.convert(data, this._slot.dataEntity, this._slot.plugtemplate.plugKey);
      //   console.log(this.data);
      // })
    }
}
