<div class="user-invoices">

  <h3 class="header">{{'user_invoices_header'|trans}}</h3>
  <div class="article-content">

    <table>
      <thead>
      <tr>
        <th>{{'user_invoices_table_invoice_nr'|trans}}</th>
        <th>{{'user_invoices_table_invoice_netto'|trans}}</th>
        <th>{{'user_invoices_table_invoice_gross'|trans}}</th>
        <th>{{'user_invoices_table_date_of_issue'|trans}}</th>
        <th>{{'user_invoices_table_status'|trans}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let invoice of data">
        <tr>
          <td><b>{{invoice.invoiceNum}}</b></td>
          <td class="text-right">{{invoice.valueNet}} &euro;</td>
          <td class="text-right">{{invoice.valueGross}} &euro;</td>
          <td>{{invoice.dateOfIssue|date:'dd.MM.YYYY HH:mm'}}</td>
          <td class="nobr">
            <ng-container *ngIf="invoice.paymentDate;else noPaymentDate">
              {{invoice.paymentStatus.name}} - {{invoice.paymentDate|date:'dd.MM.YYYY HH:mm'}}
            </ng-container>
            <ng-template #noPaymentDate>
              {{invoice.paymentStatus.name}}
            </ng-template>
          </td>
            <td><a target="_blank" href="/pdf/invoice/{{invoice.id}}.pdf" class="button-link" download="invoice_{{invoice.id}}.pdf">{{'user_invoices_btn_download'|trans}}</a></td>
        </tr>
<!--        <tr class="small">-->
<!--          <th class="border-top-none border-bottom-end" [rowSpan]="invoice.rows.length+1"></th>-->
<!--          <th>{{'user_invoices_table_row_name'|trans}}</th>-->
<!--          <th>{{'user_invoices_table_row_quantity'|trans}}</th>-->
<!--          <th>{{'user_invoices_table_row_price_nt'|trans}}</th>-->
<!--          <th>{{'user_invoices_table_row_vat'|trans}}</th>-->
<!--        </tr>-->
<!--        <ng-container *ngFor="let row of invoice.rows; let last = last">-->
<!--          <tr [ngClass]="{'border-bottom-end': last}">-->
<!--            <td>{{row.name}}</td>-->
<!--            <td class="text-center">{{row.quantity}}{{row.jm.name}}</td>-->
<!--            <td class="text-center">{{row.priceNet}} &euro;</td>-->
<!--            <td class="text-center">{{row.vatRate.display}}</td>-->
<!--          </tr>-->
<!--        </ng-container>-->
      </ng-container>
      </tbody>
    </table>

  </div>

</div>
