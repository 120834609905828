import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';

import {BaseComponent} from "../../base.component";
import Article from "./bundle.article.component.interface";


@Component({
  selector: 'bundle.article',
  templateUrl: './bundle.article.component.html',
  styleUrls: ['./bundle.article.component.scss'],
  providers: []
})

export class BundleArticleComponent extends BaseComponent{

  public _data: Article

}
