import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {FormFormService} from "../../../../services/form.api.service";


@Component({
    selector: 'bundle.user-workinghours-employer-not-approved',
    templateUrl: './user.workinghours.employer.not.approved.component.html',
    styleUrls: ['./user.workinghours.employer.not.approved.component.scss'],
})

export class UserWorkinghoursEmployerNotApprovedComponent extends BaseComponent implements OnInit{


  ngOnInit() {
    this.apiService.getByStaticUrl(ApiLink('/user-working-hours?approved=0')).subscribe(data => {
      this.data = data;
    });
  }

  dateDiff(date:string, date1:string) {
    let d = new Date(date);
    let d1 = new Date(date1);
    let minutes = (d1.getTime() - d.getTime()) / (1000 * 60);
    return `${Math.floor(minutes/60)}.${minutes%60}`
  }

  accept(id, $event) {
    $event.target.parentNode.parentNode.parentNode.classList.add('send')

    let fd = new FormData();
    fd.append('id', id);
    this.apiService.staticPost(ApiLink('/working-hours/approve'), fd ).subscribe(response => {
      console.log(response);
      if (response && response.type === 'error'){
        $event.target.parentNode.parentNode.parentNode.classList.remove('send')
      }
    })
    // console.log($event.target.parentNode.parentNode.parentNode);
  }


}
