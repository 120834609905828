<div class="blog-article">
  <ng-container *ngIf="data">

  <h2 class="title">{{data.title}}</h2>
  <div class="flexbox">

    <div>
      <div class="image-container">
        <app-core-image [url]="data.photo"></app-core-image>
      </div>
      <div class="category" [innerHTML]="data?.category?.categoryName"></div>
      <div class="tags">
        <ng-container *ngFor="let tag of data.tags">
          #{{tag.tag}}
        </ng-container>
      </div>
    </div>

    <div>
      <div class="article-content" [innerHTML]="data.content|safeHtml"></div>
    </div>

  </div>

  <div class="btn-container">
    <a class="button" [href]="'btn_id_blog'|trans|link">{{'btn_blog_back'|trans}}</a>
  </div>

  </ng-container>
</div>
