import {Component, Injector, Input, OnDestroy, OnInit, SecurityContext} from '@angular/core';

import {BaseComponent} from "../../base.component";
import FAQ from "./bundle.faq.component.interface";
import {AppFaqService} from "../../../shared/bundles/app/faq/faq.service";
import {AppVariables} from "../../../config/app.variables";


@Component({
  selector: 'bundle.faq',
  templateUrl: './bundle.faq.component.html',
  styleUrls: ['./bundle.faq.component.scss'],
  providers: []
})

export class BundleFaqComponent extends BaseComponent implements OnInit, OnDestroy{

  public _data: FAQ[];

  protected faqService

  protected subscriber

  ngOnInit() {
    this.faqService = this._injector.get(AppFaqService);
    this.subscriber = this.urlService.urlParams.subscribe((params:any) => {

      let paramsRequest = this.urlService.parseParamsToFetch(params);
      this.faqService.getAll(paramsRequest).subscribe(data => {
        this._data = data;
        console.log('faq', data);
      });

    });
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

}
