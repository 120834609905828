import {AfterContentInit, Component, Injector, Input, OnInit} from '@angular/core';

import {CoreMessage} from '../../../services/CoreMessage';
import {FormFormService} from '../../../services/form.api.service';
import {ApiLink} from "../../../utils/utils";
import {FormBaseComponent} from "../../form.base.component";
import {BaseComponent} from "../../base.component";
import {ApiService} from "../../../services/api.service";
import {UrlService} from "../../../services/urlService";
import {ConverterService} from "../../../services/converter.service";
import {AppService} from "../../../core/services/app.service";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'bundle.newsletter-activate',
  templateUrl: './bundle.newsletter.activate.component.html',
  styleUrls: ['./bundle.newsletter.activate.component.scss'],
  providers: []
})
export class BundleNewsletterActivateComponent extends BaseComponent implements OnInit{

  message: CoreMessage

  constructor(
    protected _injector: Injector,
    protected router: ActivatedRoute
  ){
    super(_injector);
  }

  ngOnInit() {

    let key = 'newsletter';
    let value =  this.router.snapshot.queryParams['newsletter'];
    let fd = new FormData();
    fd.append(key, value);
    this.apiService.staticPost(ApiLink('/confirm-newsletter'), fd).subscribe(response => {
      console.log('newsletter response',response);
      console.log(response instanceof CoreMessage)
      // if (response instanceof CoreMessage) {
        this.message = response;
      // }
    })

  }

}
