import {Injectable} from '@angular/core';
import {AppConfig} from '../../config/app.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';

import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {ApiLink} from "../../utils/utils";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private getTokenCache = null;
    protected oauth: any;
    protected apiUrl: string;
    protected getAnonymusAccesTokenInProgress;
    protected timeOutRefresh;
    protected getNewAccesTokenInProgress = null;
    protected timeOutAnonymusRefresh;
    private getNewAnonymusTokenInProgress = null;



    constructor(protected http: HttpClient, private cookieService: CookieService) {
        this.oauth = AppConfig.oauth;
        this.apiUrl = AppConfig.endpoints.serverApi;
    }

    /**
     * Pobranie accestokena podczas próby logowania
     * @param loginData
     * @returns {Observable<any>}
     */
    obtainAccessToken(loginData): Observable<any> {
        var params = {};
        params['username'] = loginData.username || loginData.email;
        params['password'] = loginData.password;
        params['grant_type'] = 'password';
        params['scope'] = '';
        params['client_id'] = this.oauth.client_id;
        params['client_secret'] = this.oauth.client_secret;


        const formData: any = new FormData();

        for(let key in params){
          formData.append(key, params[key]);
        }

      // for (var pair in x.en()) {
      //   console.log(pair[0]+ ', ' + pair[1]);
      // }
      // x.forEach((val,key) => {
      //   console.log(val, key)
      // })
      // for (let [key, value] of x) {
      //   console.log(`${key}: ${value}`)
      // }

        // return;

      // grant_type=password&client_id=mkk-priv&client_secret=asdf&scope=&username=mkkx%40niepodam.pl&password=mkkx

        // let oldToken = this.getCurrentToken();
        // if (oldToken) {
        //     params['old_token'] = oldToken;
        // }

        // let headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded'});
        // let options = {headers};

        return this.http.post(ApiLink('/oauth/token', true), formData)
          // .pipe(
          //   map(response => response)
        // );
    }

    /**
     * Pobranie anonimowego accestokena
     * @param loginData
     * @returns {Observable<any>}
     */
    obtainAnonymusAccessToken(): any {

        if (!this.getAnonymusAccesTokenInProgress) {
            let params = {};
            params['grant_type'] = 'client_credentials';
            params['client_id'] = this.oauth.client_id;
            params['client_secret'] = this.oauth.client_secret;
            let headers = new HttpHeaders({'Content-type': 'application/json;charset=utf-8'});
            let options = {headers};

            this.getAnonymusAccesTokenInProgress = this.http.post(this.apiUrl + '/accesstokens', params, options).pipe(
                map(response => response)
            );
        }

        return this.getAnonymusAccesTokenInProgress;
    }

    saveToken(token) {
        var expireDate = new Date().getTime() + (1000 * token.expires_in);
        this.cookieService.set('access_token', token.access_token, expireDate, '/');
        this.cookieService.set('refresh_token', token.refresh_token, expireDate, '/');

        var validity = token.expires_in;
        let currentTime = new Date().getTime();
        this.cookieService.set('access_token_ex', (currentTime + validity * 1000).toString(), expireDate);
        if (validity > 10) {
            validity -= 10;
        }
        return this.cookieService.get('access_token');
        // this.timeOutRefresh = setTimeout(() => this.refreshAccessToken(), validity * 1000);
    }

    /**
     * Refresh token - funkcja pobierajaca nowy token na podstawie refresh tokena
     */
    refreshAccessToken() {
        // if (this.timeOutRefresh) {
        //     clearTimeout(this.timeOutRefresh);
        // }

        // if (!this.getNewAccesTokenInProgress) {
      if (this.canRefreshToken()) {
        var params = {};
        params['grant_type'] = 'refresh_token';
        params['client_id'] = this.oauth.client_id;
        params['client_secret'] = this.oauth.client_secret;
        params['refresh_token'] = this.cookieService.get('refresh_token');

        const formData: any = new FormData();
        for (let key in params) {
          formData.append(key, params[key]);
        }

        // return new Promise((resolve, reject) => {
        return this.http.post(ApiLink('/oauth/token', true), formData)
          // .pipe(
          // map(data => this.saveToken(data)),
        // )

// this.http.post(ApiLink('/oauth/token', true), formData).tap()
//             .subscribe(data => {
//
//               this.saveToken(data)
//               // resolve(this.saveToken(data));
//               // this.getNewAccesTokenInProgress = null;
//             },
//             // () => {
//             // }
//           );

        // })

      }


            // this.getNewAccesTokenInProgress = this.http.post(ApiLink('/oauth/token', true), formData).subscribe(
            //   data => {
            //     this.saveToken(data);
            //     // this.getNewAccesTokenInProgress = null;
            //   },
            //   // () => {
            //   // }
            // );

        // }

        // return this.getNewAccesTokenInProgress;
    }

    canRefreshToken() {
      let timeInSecondToShouldRefresh =40
      let currentTime = new Date().getTime();
      let diff = parseFloat(this.cookieService.get('access_token_ex')) - currentTime;
      console.log(diff);
      return (diff/1000 < timeInSecondToShouldRefresh) && (diff/1000 > 0)
    }

    saveAnonymusToken(token): void {
        this.getNewAnonymusTokenInProgress = null;
        var expireDate = new Date().getTime() + (1000 * token.expires_in);
        this.cookieService.set('access_token_anonim', token.access_token, expireDate, '/');
        var validity = token.expires_in;
        let currentTime = new Date().getTime();
        this.cookieService.set('access_token_anonim_ex', (currentTime + validity * 1000).toString(), expireDate);
        if (validity > 10) {validity -= 10;}
        this.timeOutAnonymusRefresh = setTimeout(() => this.refreshAnonymusToken(), validity * 1000);
    }

    refreshAnonymusToken(): void {
        if (this.timeOutAnonymusRefresh) {
            clearTimeout(this.timeOutAnonymusRefresh);
        }

        /** medota pobiera nowy acces token na podstawie starego acces tokena ale tylko dla tokena anonimowego **/
        if (!this.getNewAnonymusTokenInProgress) {
            var params = {};
            params['grant_type'] = 'refresh_anonymus_token';
            params['client_id'] = this.oauth.client_id;
            params['client_secret'] = this.oauth.client_secret;
            params['refresh_token'] = this.cookieService.get('access_token_anonim');
            let headers = new HttpHeaders({'Content-type': 'application/json;charset=utf-8'});
            let options = {headers: headers};
            this.getNewAnonymusTokenInProgress = this.http.post(this.apiUrl + '/accesstokens', params, options).pipe(
                map(response => response)
            );

            this.getNewAnonymusTokenInProgress.subscribe(
                data => {
                    this.saveAnonymusToken(data);
                },
                () => {
                }
            );
        }
    }


    checkCredentials() {
        if (this.cookieService.get('access_token')) {
            return true;
        }
        return false;
    }

    logout(): void {
      // this.cookieService.set('refresh_token', token.refresh_token, expireDate, '/');

      // var validity = token.expires_in;
      // let currentTime = new Date().getTime();
      // this.cookieService.set('access_token_ex', (currentTime + validity * 1000).toString(), expireDate);

        this.cookieService.delete('access_token');
        this.cookieService.delete('refresh_token');
        this.cookieService.delete('access_token_ex');
        if (!this.cookieService.get('access_token_anonim')) {
            this.obtainAnonymusAccessToken();
        }
        location.reload();
    }

    getCurrentToken(): any {
        let currentTime = new Date().getTime();
        currentTime = currentTime + 10 * 1000; // 10 sekund przed końcem ważnośi tokena zostanie pobrana jego aktualizacja
        if (this.cookieService.get('access_token') && currentTime < parseFloat(this.cookieService.get('access_token_ex'))) {
            return this.cookieService.get('access_token');
        }
        if (this.cookieService.get('access_token_anonim') && currentTime < parseFloat(this.cookieService.get('access_token_anonim_ex'))) {
            return this.cookieService.get('access_token_anonim');
        }

        return null;
    }

    getToken() {

      // return new Promise((resolve, reject) => {
      //
      //   let currentTime = new Date().getTime();
      //   currentTime = currentTime + 10 * 1000; // 10 sekund przed końcem ważnośi tokena zostanie pobrana jego aktualizacja
      //   if (this.cookieService.get('access_token')) {
      //     if (!this.cookieService.get('access_token_ex') || currentTime > parseFloat(this.cookieService.get('access_token_ex'))) {
      //       /** próba pobrania nowego acces tokena po refresh tokenie **/
      //       resolve(this.refreshAccessToken());
      //       // resolve(this.cookieService.get('access_token'));
      //     } else {
      //       /** zwrócenie obecnego accestokena - prawdopodobnie jest aktualny **/
      //       resolve(this.cookieService.get('access_token'));
      //     }
      //   }else {
      //     resolve('')
      //   }
      //
      // })
      // return null
      //   let currentTime = new Date().getTime();
        // currentTime = currentTime + 10 * 1000; // 10 sekund przed końcem ważnośi tokena zostanie pobrana jego aktualizacja
        if (this.cookieService.get('access_token')) {
            // if (!this.cookieService.get('access_token_ex') || currentTime > parseFloat(this.cookieService.get('access_token_ex'))) {
            //     /** próba pobrania nowego acces tokena po refresh tokenie **/
            //     this.refreshAccessToken();
            //     return this.cookieService.get('access_token');
            // } else {
                /** zwrócenie obecnego accestokena - prawdopodobnie jest aktualny **/
              this.refreshAccessToken();

              return this.cookieService.get('access_token');
            // }
        }
        // if (this.cookieService.get('access_token_anonim')) {
        //     if (!this.cookieService.get('access_token_anonim_ex') || currentTime > parseFloat(this.cookieService.get('access_token_anonim_ex'))) {
        //         /** próba pobrania nowego acces tokena po refresh tokenie **/
        //         this.refreshAnonymusToken();
        //         return this.cookieService.get('access_token_anonim');
        //     } else {
        //         /** zwrócenie obecnego accestokena - prawdopodobnie jest aktualny **/
        //         return this.cookieService.get('access_token_anonim');
        //     }
        //
        // }

        // /** jeśli nie ma tokenów które można zwrócić wówczas pobieram nowy token **/
        // let token = '';
        // this.obtainAnonymusAccessToken().subscribe(
        //     data => {
        //         this.saveAnonymusToken(data);
        //         token = this.cookieService.get('access_token_anonim');
        //     },
        //     () => {
        //     }
        // );

        // return token;
    }

    getOptions(isLd = false, addOptions = {}) {
        // jeśli włączono w konfiguracji obsługe tokenów
        let enabled = true;

        let options = {};
        if (enabled) {
          let token: string = this.getToken();

            if (token && token !== undefined && token !== 'undefined') {
                let headers = new HttpHeaders({
                    'Cache-Control': 'no-cache', 'Pragma': 'no-cache',
                    'Authorization': 'Bearer ' + token,
                    // 'Content-Type': 'application/json;charset=utf-8',
                    "accept": isLd ? "application/ld+json":"application/json"
                });
                options = {headers};

            } else {
                let headers = new HttpHeaders({
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    // 'Content-Type': 'application/json;charset=utf-8',
                    "accept": isLd ? "application/ld+json":"application/json"
                });
                options = {headers};
            }
        } else {
            let headers = new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                // 'Content-Type': 'application/json;charset=utf-8',
                "accept": isLd ? "application/ld+json":"application/json"
            });
            options = {headers};
        }
        return {...options,...addOptions};
    }

}
