import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogCategoryService} from '../../../shared/bundles/app/blog_category/blog_category.service';
import {UrlService} from '../../../services/urlService';
import {AppCourseCategoryService} from '../../../shared/bundles/app/course_category/course_category.service';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.courses-categories',
  templateUrl: './bundle.courses.categories.component.html',
  styleUrls: ['./bundle.courses.categories.component.scss'],
  providers: []
})

export class BundleCoursesCategoriesComponent extends BaseComponent implements OnInit{
    // _elements = [];
    //
    // selectedElement = null;
    //
    // constructor(
    //   protected converter: ConverterService,
    //   protected apiService: ApiService,
    //   protected courseCategoryService: AppCourseCategoryService,
    //   protected urlService: UrlService
    // ) {
    //   // super();
    // }
    //
    ngOnInit() {
    //   this.urlService.urlParams.subscribe((params:any) => {
    //     this.selectedElement = params.id || null;
    //     console.log("asd")
    //   })
    //   this.courseCategoryService.getAll().subscribe(data => {
    //     this._elements = data;
    //     console.log('courses categories', data);
    //   })
    }
}
