<div class="user-workinghours">

  <bundle class="date-changer" [format]="'MM.yyyy'" (changeEvent)="changeMonth($event)" #monthPicker></bundle>

  <h3 class="header">{{'working_hours_header_summary'|trans}}</h3>
  <bundle class="user-monthly" #statsComponent [date]="date" [me]="me"></bundle>

  <h3 class="header">{{'working_hours_header_details'|trans}}</h3>
  <div class="article-content">

    <table *ngIf="data">
      <thead>
      <tr>
        <th>{{'working_hours_table_job'|trans}}</th>
        <th>{{'working_hours_table_time'|trans}}</th>
        <th>{{'working_hours_table_time_diff'|trans}}</th>
        <th>{{'working_hours_table_time_status'|trans}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="data.length === 0; else list">
        <tr>
          <td colspan="4">{{'working_hours_table_empty'|trans}}</td>
        </tr>
      </ng-container>
      <ng-template #list >
        <ng-container *ngFor="let object of data">
        <tr>
          <td><a [href]="'page_id_offer'|trans|link:object.jobOffer.slug:object.jobOffer.cmsid">{{object.jobOffer.title}}</a></td>
          <td>
            <div class="time">
              <div>od <b>{{object.timeStart|date:'dd-MM-YYYY HH:mm'}}</b></div>
              <div>do <b>{{object.timeEnd|date:'dd-MM-YYYY HH:mm'}}</b></div>
            </div>
          </td>
          <td>{{dateDiff(object.timeStart, object.timeEnd)}}</td>
          <td>
            <ng-container *ngIf="object.approved; else notApproved"><span class="approved">{{'working_hours_approved'|trans}}</span>
            </ng-container>
            <ng-template #notApproved><span class="not-approved">{{'working_hours_not_approved'|trans}}</span></ng-template>
          </td>
        </tr>
        </ng-container>
      </ng-template>
      </tbody>
    </table>

    <h3 class="header">{{'working_hours_header_form'|trans}}</h3>
    <div class="form-blue">
      <!--    <bundle class="form" [formForm]="formForm"></bundle>-->
      <form action="{{createFormLink()}}" method="post" (ngSubmit)="submitFormAction($event)">
        <div class="description">{{'working_hours_form_desc'|trans}}</div>

        <ng-container *ngIf="message">
          <app-core-message [message]="message"></app-core-message>
        </ng-container>

        <div class="formSections">
          <div class="formGroup">
            <div class="flexbox">


              <div class="form_component component_datetime">
                <label for="user_workinghour_date_start">{{'working_hours_form_label_date_start'|trans}}</label>
                <input datetimepicker id="user_workinghour_date_start" type="text" name="timeStart" required="">
              </div>
              <div class="form_component component_datetime">
                <label for="user_workinghour_date_end">{{'working_hours_form_label_date_end'|trans}}</label>
                <input datetimepicker id="user_workinghour_date_end" type="text" name="timeEnd" required="">
              </div>

            </div>
          </div>
        </div>

        <div class="formSections">
          <div class="formGroup">
            <div class="flexbox">


              <div class="form_component component_select" *ngIf="jobOffers.length > 0">


                <label for="user_profile_file_type">{{'working_hours_form_label_offer'|trans}}</label>
                <form-custom-select [selected]="jobOffers[0].id" #form_custom_select_as_select
                                    (onChange)="form_select.value = $event" [options]="getOptions(jobOffers, 'id', 'title')">
                </form-custom-select>

                <select style="display: none" name="joboffer" id="user_profile_file_type" required="" #form_select>
                  <ng-container *ngFor="let job of jobOffers">
                    <option value="{{job.id}}">{{job.title}}</option>
                  </ng-container>
                </select>

              </div>

            </div>
          </div>
        </div>

        <!--    /user-offers-->
        <div class="formSections">
          <div class="formGroup">
            <div class="flexbox">
              <div class="form_component component-submit">
                <input type="submit" class="button" value="Wyślij">
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>


</div>
