<div class="flexbox" *ngIf="_data">
  <ng-container *ngFor="let element of _data; let i = index">
    <bundle class="blog-card" [data]="element"></bundle>
    <ng-container *ngIf=" i === 1">
      <bundle class="box-blue view-desktop" [boxId]="'box_id_blog_list'|trans"></bundle>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="_data.length < 2">
    <ng-container *ngIf="_data.length == 1">
      <div class="break"></div>
    </ng-container>
    <ng-container *ngIf="_data.length == 0">
      <div class="break"></div>
      <div class="break"></div>
    </ng-container>
    <bundle class="box-blue view-desktop" [boxId]="'box_id_blog_list'|trans" ></bundle>
  </ng-container>
  <div class="break"></div>
  <bundle class="box-blue view-mobile" [boxId]="'box_id_blog_list'|trans" ></bundle>

</div>

<bundle class="pagination" #pagination></bundle>
