import { Pipe, PipeTransform } from '@angular/core';
import {AppVariables} from '../config/app.variables';
import {AppConfig} from '../config/app.config';

@Pipe({
  name: 'price',
  pure: false
})

export class PricePipe implements PipeTransform {

  transform(value: string, ...args: any[]): number {
    return this.wacky_round(value, 2);
  }

  wacky_round(number, places) {
    var multiplier = Math.pow(10, places+2); // get two extra digits
    var fixed = Math.floor(number*multiplier); // convert to integer
    fixed += 44; // round down on anything less than x.xxx56
    fixed = Math.floor(fixed/100); // chop off last 2 digits
    return fixed/Math.pow(10, places);
  }
}
