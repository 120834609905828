import {Component, ComponentRef, ElementRef, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
// import {StructurePage} from '../../shared/bundles/structure/page/page.model';
import {HttpClient} from '@angular/common/http';
// import {RoutingPageService} from '../../shared/bundles/structure/routing/page.service';
import {CoreLanguageService} from '../../shared/bundles/core/language/language.service';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {AppConfig} from '../../config/app.config';
import {ActivatedRoute, NavigationEnd, Params, Route} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {Router} from '@angular/router';
// import {StructurePageService} from '../../shared/bundles/cms/page/page.service';
import {CMSSlot} from '../../shared/bundles/cms/slot/slot.model';
import {CMSPage} from '../../shared/bundles/cms/page/page.model';
import {AppService} from '../services/app.service';
import {RoutingPageService} from '../../services/routing.page.service';
import {map} from 'rxjs/operators';
import {DynamicComponentService} from '../../services/dynamic.component.service';
import {ConverterService} from '../../services/converter.service';
import {UrlService} from '../../services/urlService';
import {BaseComponent} from '../../bundles/base.component';
import {CookieService} from "ngx-cookie-service";
import {Meta} from "@angular/platform-browser";
// import {StructureSection} from "../../shared/bundles/structure/section/section.model";
// import {StructureBlock} from "../../shared/bundles/structure/block/block.model";
// import {StructureSlot} from "../../shared/bundles/structure/slot/slot.model";



@Component({
    selector: 'app-core-contentsection',
    templateUrl: './contentsection.component.html',
    styleUrls: ['./contentsection.component.scss'],
    providers: []
})

export class ContentsectionComponent extends BaseComponent implements OnInit{
    @Input() structureBlock;

    // @ViewChild()
    // @ViewChildren('slotContainer') attendeeInputs: QueryList<ElementRef>;
    // slotContainer

    page: CMSPage;

    protected lastPageUrl = "";

    constructor(protected _injector: Injector,

                private http: HttpClient,
                private routingPageService: RoutingPageService,
                private languageService: CoreLanguageService,
                private route: ActivatedRoute,
                private urlP: LocationStrategy,
                private _router: Router,
                private cookieService: CookieService,
                private metaService: Meta

                // private componentService: DynamicComponentService,
                // protected appService: AppService,
                // protected converter: ConverterService,
                // protected urlService: UrlService,
                // public structurePageService: StructurePageService
    ) {
        // super(structurePageService);
      super(_injector);
    }

    ngOnInit() {


      this.converter.converterMap = this.route.snapshot.data['converters'];
      AppConfig.structurePageList = this.route.snapshot.data['pages'];

      console.log('ppConfig.structurePageList ', AppConfig.structurePageList )

      this.urlService.url.subscribe(url => {
        let urlForStructurePage = this.urlService.getUrlWithoutLang();
        if (urlForStructurePage) {
          this.getPageStructure(urlForStructurePage);
        }
      });

      let queryParams = this.route.snapshot.queryParams;
      if (queryParams['ref']){
        this.cookieService.set('ref', queryParams['ref']);
        const urlWithoutQueryParams = this._router.url.substring(0, this._router.url.indexOf('?'));
        this._router.navigateByUrl(urlWithoutQueryParams, {replaceUrl: true});
      }else {
        this.urlService.changeUrl(window.location.pathname + window.location.search)
      }

      this._router.events.subscribe(ev => {
        if(ev instanceof NavigationEnd) {
          this.urlService.changeUrl(ev.url);
        }
      });
    }

    getPageStructure(url) {
      if (this.lastPageUrl === url){
        return;
      }
      this.routingPageService.get(url).subscribe((page: CMSPage) => {
        if (page && page.redirectToType === 'insideUrl' && page.redirectToUrl) {
          this._router.navigateByUrl(page.redirectToUrl);
        }
        if (page && page.redirectToType === 'file' && page.redirectToUrl) {
          window.location.replace(page.redirectToUrl);
        }
        if (page && page.redirectToType === 'outsideUrl' && page.redirectToUrl) {
          window.location.replace(page.redirectToUrl);
        }
        this.page = page;
        console.log('page',page);
        this.lastPageUrl = url;
        if (this.page) {
          // this.setMetadata(this.page.structurePageSeoMetaData || null);
        }
      });
    }


    updateMeta(obj) {

      for (let key in obj) {
        this.metaService.updateTag({ name: key, content: obj[key]})
      }

      // <meta property="og:url"                content="https://www.winpecker.devdigitalberry.pl" />
      // <meta property="og:type"               content="article" />
      // <meta property="og:title"              content="Winpecker" />
      // <meta property="og:description"        content="Winpecker?" />
      // <meta property="og:image"              content="https://www.winpecker.devdigitalberry.pl/files/public/logo/logo.png" />

    }


    generateStyle(element) {
      let retval = "";
      if(element.cssOptions) {
        let cssPropsArr = [];
        element.cssOptions.elements.forEach(element => {
          let o = {};
          for(let elementKey in element){
            if(elementKey[0] !="@"){
              o[elementKey] = element[elementKey]
            }
          }
          cssPropsArr.push(o);
        })
        retval = cssPropsArr.filter(el => el.prop).map(el => `${el.prop}: ${el.val};`).join("")
      }
      return retval
    }

    public convertPlugKey(plugKey: string) {
      return plugKey.replace(/\./g, "-");
    }

    // setMetadata(metadata: SettingsSeoMetaData = null) {
    //   if(metadata){
    //     if (document.title != metadata.metaTitle) {
    //       document.title = metadata.metaTitle;
    //     }
    //
    //     var metaKey = '';
    //     var metaDesc = '';
    //     var metaRobots = 'index,follow';
    //
    //     if (metadata.metaKeywords)
    //     {
    //       metaKey = metadata.metaKeywords;
    //     }
    //     if (metadata.metaDescription)
    //     {
    //       metaDesc = metadata.metaDescription;
    //     }
    //     if (metadata.metaRobots)
    //     {
    //       metaRobots = metadata.metaRobots;
    //     }
    //     document.querySelector('meta[name="keywords"]').setAttribute("content", metaKey);
    //     document.querySelector('meta[name="description"]').setAttribute("content",  metaDesc);
    //     document.querySelector('meta[name="robots"]').setAttribute("content",  metaRobots);
    //   }else{
    //     document.title = '';
    //     document.querySelector('meta[name="keywords"]').setAttribute("content", '');
    //     document.querySelector('meta[name="description"]').setAttribute("content", '');
    //     document.querySelector('meta[name="robots"]').setAttribute("content", 'index,follow');
    //   }
    // }

    // setAnimation(slot: StructureSlot, type) {
    //   var result = '';
    //
    //   if (slot.structureSlotAnimation) {
    //     if(type === 'data-type') {
    //       result = slot.structureSlotAnimation.aType;
    //     }
    //     if(type === 'data-duration') {
    //       result = slot.structureSlotAnimation.aDuration.toString();
    //     }
    //     if(type === 'data-delay') {
    //       result = slot.structureSlotAnimation.aDelay.toString();
    //     }
    //     if(type === 'data-offset' && slot.structureSlotAnimation.runTopOffset) {
    //       result = slot.structureSlotAnimation.runTopOffset.toString();
    //     }
    //     if(type === 'class-animated') {
    //       if (slot.structureSlotAnimation.isRunIfVisible) {
    //         result = 'wait_animated';
    //       }
    //       else {
    //         result =  'animated' + ' '  + slot.structureSlotAnimation.aDuration + ' ' + slot.structureSlotAnimation.aType + ' db-' + slot.structureSlotAnimation.aDelay;
    //       }
    //     }
    //   }
    //   return result;
    // }
}
