import {Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AppConfig} from '../config/app.config';
import {CoreLanguageService} from '../shared/bundles/core/language/language.service';
import {AppVariables} from '../config/app.variables';
import {Languages} from '../utils/Languages';
import {LocalesService} from '../services/locales.service';

@Injectable({ providedIn: 'root' })
export class LanguagesResolver implements Resolve<any> {

  private cache = null;

  constructor(private languageService: CoreLanguageService, private router: Router, private localesService: LocalesService) {}

  async resolve() {
    if (!this.cache) {
      let languagesList = await this.languageService.getAll().toPromise()

      console.log(languagesList);

      // return;
      AppVariables.Languages = new Languages(languagesList);

      let languageFromUrl = null;
      let urlArr = window.location.pathname.split('/');
      if (urlArr[1]) {
        languageFromUrl = urlArr[1];
        AppConfig.language = languageFromUrl;
      }
      let findByLangFromUrl = languagesList.find((subject) => subject.shortName === languageFromUrl);
      let defaultLang = languagesList.find((subject) => subject.isDefault);

      if (!findByLangFromUrl) {
        AppConfig.language = defaultLang.shortName;
      } else {
        if (languageFromUrl === defaultLang.shortName) {
          this.router.navigate(['/']);
        }
        AppConfig.language = findByLangFromUrl.shortName;
      }
      this.cache = languagesList;

      let locales = {};
      (await this.localesService.getByLang(AppConfig.language).toPromise()).forEach(locale => {
        locales[locale.objKey] = locale.objVal
      });

      AppVariables.locale = locales;
    }

    // console.log(e);
    // return {"asd": "ASd"};
    // return this.service.getHero(route.paramMap.get('id'));
  }
}
