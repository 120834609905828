import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {BaseComponent} from '../../base.component';
import {CmsElementApiService} from "../../../services/cmsElement.api.service";
import {ApiLink, getLocale, link} from "../../../utils/utils";

@Component({
  selector: 'bundle.courses-article',
  templateUrl: './bundle.courses.article.component.html',
  styleUrls: ['./bundle.courses.article.component.scss'],
  providers: []
})

export class BundleCoursesArticleComponent extends BaseComponent implements OnInit{

  protected cmsElementApiService

  message

  ngOnInit() {
    this.cmsElementApiService = this._injector.get(CmsElementApiService);
    this.cmsElementApiService.getByCmsId(this.urlService.params['cmsid']).subscribe(data => {
      this.data = data.length === 1 ? data[0] : data;
    })
  }

  join() {
    if (this.appService.checkCredentials()){
      let fd = new FormData();
      fd.append('course', this.data.id);
      this.apiService.staticPost(ApiLink('/course-registration'),fd).subscribe(response => {
        if (response) {
          this.message = response;
        }
      })
    }else {
      this.urlService.redirect(link(getLocale('page_id_login')) + `?redirect=${window.location.pathname}`)
    }
  }

}
