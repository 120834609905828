import {Component, Input, OnInit, Output, EventEmitter, Injector} from '@angular/core';
import {ApiLink} from "../../../utils/utils";
import {BaseComponent} from "../../base.component";
import { DatePipe } from '@angular/common';
import {PagesMap, workerTypes} from "../../user/pagesmap";


@Component({
  selector: 'bundle.user-monthly',
  templateUrl: './bundle.user.monthly.component.html',
  styleUrls: ['./bundle.user.monthly.component.scss'],
  providers: [DatePipe]

})

export class BundleUserMonthlyComponent extends BaseComponent implements OnInit {

  jobOffers = [];
  @Input() me;

  stats;

  colspan


  private _date = new Date()
  private datePipe;

  constructor(
    protected _injector: Injector,
    public pagesService: PagesMap
  ) {
    super(_injector);
    this.datePipe = this._injector.get(DatePipe);
  }

  @Input()
  set date(date) {
    this._date = date;
    this.getStats(date);
  }
  get date() {
    return this._date;
  }

  ngOnInit(): void {
    this.apiService.getByStaticUrl(ApiLink('/user-offers')).subscribe(data => {
      this.jobOffers = data;
    })

  }

  getStats(date: Date = this.date) {
    this.apiService.getByStaticUrl(ApiLink(`/user-monthly?date=${this.datePipe.transform(date, 'yyyy-MM-dd')}`)).subscribe(data => {
      this.stats = data;

      if (this.canSeeRecruiter() || this.canSeeEmployer()) {
        this.colspan = 5;
      }else {
        this.colspan = 4
      }

    })
  }

  objLen(obj){
    return Object.keys(obj).length
  }

  getOfferById(id) {
    return this.jobOffers.find(el => el.id == id);
  }

  dateDiffFromSecond(seconds) {
    let minutes = seconds / 60;
    let minDisplay = minutes%60 < 10 ? '0' + minutes%60 : minutes%60;
    return `${Math.floor(minutes/60)}:${minDisplay}h`
  }


  canSeeRecruiter() {
    let available = [1];
    // @ts-ignore
    return available.includes(workerTypes[this.me.role.role]);
  }

  canSeeEmployer() {
    let available = [2];
    // @ts-ignore
    return available.includes(workerTypes[this.me.role.role]);
  }


}
