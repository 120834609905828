import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";
import {AppArticleService} from "../../../../shared/bundles/app/article/article.service";


@Component({
    selector: 'bundle.user-after-pay',
    templateUrl: './user.after.pay.component.html',
    styleUrls: ['./user.after.pay.component.scss'],
})

export class UserAfterPayComponent extends BaseComponent implements OnInit{

  articleService
  route

  ngOnInit() {
    this.articleService = this._injector.get(AppArticleService)
    this.route = this._injector.get(ActivatedRoute)
    this.articleService.getAll(`?title=${this.route.snapshot.queryParams['msg']}`).subscribe(articles => {
      console.log(articles.length > 0 ? articles[0] : null)
      this.data = articles.length > 0 ? articles[0] : null;
    })

  }


}
