import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base.component";
import {FormBaseComponent} from "../../form.base.component";
import {ApiLink} from "../../../utils/utils";
import {ActivatedRoute} from "@angular/router";
import {CoreMessage} from "../../../services/CoreMessage";


@Component({
  selector: 'bundle.remember',
  // templateUrl: './../../form/form/bundle.form.form.component.html',
  templateUrl: './user.remember.component.html',
  styleUrls: ['./user.remember.component.scss'],
})

export class UserRememberComponent extends FormBaseComponent implements OnInit{

  protected route
  message

  ngOnInit() {
    this.route = this._injector.get(ActivatedRoute);
  }

  submitPost(form, $event, formvalue) {

    console.log(form, $event, formvalue)

    let fd = new FormData($event.target);

    let hash = this.route.snapshot.queryParams['hash']
    fd.append('hash', hash);

    this.apiService.staticPost(ApiLink('/password-set'), fd ).subscribe(response => {
      this.formService.clearFormMessage(this.formForm.formKey);
      let result = this.formService.retriveResponse(this.formForm.formKey, response, false, true);
      console.log(result instanceof CoreMessage);
      if (result instanceof CoreMessage) {
        this.message = result;
      } else {
        this.forValidationErrorList = result;
      }
      console.log(response);

    })


  }

}
