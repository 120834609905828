import { Pipe, PipeTransform } from '@angular/core';
import {getLocale} from '../utils/utils';

@Pipe({
  name: 'trans',
  pure: false
})
/**
 * Prosty pipe do do tłumaczeń.
 */
export class TransPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return getLocale(value, ...args);
  }
}
