<div class="user-job-offers-recruiter">



  <div class="header">{{'user_job_offer_recruiter_header'|trans}}</div>

  <div class="article-content">
  <table>
    <tbody>
    <ng-container *ngFor="let offer of data">
      <tr>
        <td>
          <a class="button-link" [href]="'page_id_offer'|trans|link:offer.slug:offer.cmsid">{{offer.title}}</a>
        </td>
<!--        <td><span class="button" (click)="share()">{{'user_job_offer_recruiter_btn_share'|trans}}</span></td>-->
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<!--  <ul>-->
<!--  <ng-container *ngFor="let offer of data">-->

<!--    <li>-->
<!--    <a class="button-link" [href]="'page_id_offer'|trans|link:offer.slug:offer.cmsid">{{offer.title}}</a>-->
<!--    </li>-->
<!--  </ng-container>-->
<!--  </ul>-->

</div>
