<div class="newsletter-activate">

  <h3 class="header">{{'newsletter_activate_header'|trans}}</h3>
  <ng-container *ngIf="message === undefined; else activated">
    <div class="loading">
      {{'newsletter_activate_waiting'|trans}}
      <div class="loaderComponent">
        <div class="logo"></div>
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #activated>
    <div class="message">
    <app-core-message [message]="message"></app-core-message>
    </div>
  </ng-template>

</div>
