import {Component, OnInit, SecurityContext} from '@angular/core';

import {BaseComponent} from "../base.component";
import Card from "./bundle.card.interface";

@Component({
  selector: 'bundle.card',
  templateUrl: './bundle.card.component.html',
  styleUrls: ['./bundle.card.component.scss'],
  providers: []
})

export class BundleCardComponent extends BaseComponent{

  _data: Card

}
