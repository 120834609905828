
<div>
  <ng-container *ngIf="_data">
    <bundle class="category-nav" [elements]="_data">
    <ng-container *ngIf="times && times.length > 0">
        <form-custom-select [options]="times" [settings]="customSelectSettings" [selected]="'0'" (onChange)="changeTime($event)"></form-custom-select>
    </ng-container>
    <ng-container *ngIf="accommodation && accommodation.length > 0">
         <form-custom-select [options]="accommodation" [selected]="'0'" (onChange)="changeAcc($event)"></form-custom-select>
    </ng-container>
    </bundle>
  </ng-container>

</div>

