<ul *ngIf="menu?.length > 0">
    <ng-content></ng-content>
    <ng-container *ngFor="let menuItem of menu; let i=index">
        <ng-container>
          <li [ngClass]="{'active': didLinkIsActive(menuItem)}">
          <span routerLink="{{menuItem.url|href}}" (click)="toggleMobileMenu()"
                [innerHtml]="menuItem.title|safeHtml">
          </span>
<!--          <app-core-nav routerLinkActive="active" *ngIf="recursive" [recursive]="recursive" [menuAll]=this.menuAll-->
<!--                        [menu]=this.getListByParentId(menuItem.id) [isSubmenu]="true"></app-core-nav>-->
          </li>
        </ng-container>
    </ng-container>
</ul>
