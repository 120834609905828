import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {AppConfig} from '../config/app.config';
import {map, catchError} from 'rxjs/operators';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingPageService extends ApiService{

  /**
   * Pobiera strukturę strony dla podanej podstrony z parametru.
   * @param url
   */
  get(url: string = ""): Observable<any> {
    this.request$.emit('starting');
    var requestUrl = this.apiUrl + '/' + AppConfig.language + '/pages/routingpages';
    if (url) {
      requestUrl += '?url=' + url;
    }

    // console.log('routingPagesService url',url)

    return this.http.get(requestUrl, this.appService.getOptions(false)).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  staticGet(url) {
    this.request$.emit('starting');
    return this.http.get(url, this.appService.getOptions()).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

}
