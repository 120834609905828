<div class="flexbox" *ngIf="_data">
  <ng-container *ngIf="_data.length === 0;else list">
    <div class="empty-list">{{'list_empty'|trans}}</div>
  </ng-container>
  <ng-template #list>
    <ng-container *ngFor="let element of _data; let i = index">
      <bundle class="course-card" [data]="element"></bundle>
      <ng-container *ngIf="(i+1)%3 === 0 && _data.length > 3">
        <div class="line"></div>
      </ng-container>
    </ng-container>
    <bundle class="course-card"></bundle>

    <div class="element"></div>
  </ng-template>
</div>
