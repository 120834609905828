<div class="blue-box-container">
  <app-core-svg class="svg-red" [url]="'/assets/staticresource/project/Component8_1.svg'"></app-core-svg>
  <app-core-svg class="svg-orange" [url]="'/assets/staticresource/project/Path10.svg'"></app-core-svg>
  <app-core-svg class="svg-green" [url]="'/assets/staticresource/project/Path15.svg'"></app-core-svg>
  <div class="box" *ngIf="data">
    <h4 class="header" [innerHTML]="data?.title" *ngIf="data?.title"></h4>
    <app-core-image *ngIf="data?.photo" [url]="data?.photo" [method]="'adjust'" [width]="626" [height]="377" [quality]="80"></app-core-image>
    <div class="article-content" *ngIf="data?.content" [ngClass]="{'half': data?.path }" [innerHTML]="data?.content"></div>
    <div>
      <a *ngIf="data?.url" href="{{data?.url}}" class="button-blue">{{data?.urlLabel}}</a>
    </div>
  </div>
</div>
