import {Component, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AppJobOffer} from './job_offer.model';
import {Observable} from 'rxjs/Observable';

import {AppJobOfferBaseService} from './job_offer.base.service';
import {AppService} from '../../../../core/services/app.service';
import {AppConfig} from '../../../../config/app.config';
import {catchError, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AppJobOfferService extends AppJobOfferBaseService {

}
