import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";


@Component({
    selector: 'bundle.user-job-offer',
    templateUrl: './user.job.offer.component.html',
    styleUrls: ['./user.job.offer.component.scss'],
})

export class UserJobOfferComponent extends BaseComponent implements OnInit{

  applicationService

  ngOnInit() {
    // this.applicationService = this._injector.get(AppJobApplicationService);
    this.apiService.getByStaticUrl(ApiLink('/user-applications')).subscribe(data => {
      console.log('my-applications',data);
      this.data = data;
    })
    // this.applicationService.getAll().subscribe(data => {
    //   console.log('application', data);
    // })
  }

}
