<div class="latest-events">

  <h2 class="header">{{"latest_events_header"|trans}}</h2>


  <div class="btns">
      <div [ngClass]="{'button-gray': selectedEntity != 0, 'button-blue': selectedEntity === 0}"
           (click)="getListBy(0)">{{"Oferty pracy"}}</div>
      <div [ngClass]="{'button-gray': selectedEntity != 1, 'button-blue': selectedEntity === 1}"
           (click)="getListBy(1)">{{"Kursy"}}</div>
  </div>


  <ng-container *ngIf="_data == null">
    <div class="empty-list">{{'list_empty'|trans}}</div>
  </ng-container>
  <ng-container *ngIf="_data && _data.length > 0">
    <div class="flexbox">

    <ng-container *ngFor="let element of _data">
      <ng-container *ngIf="selectedEntity === 0">
        <bundle class="offert-card" [data]="element"></bundle>
      </ng-container>
      <ng-container *ngIf="selectedEntity === 1">
        <bundle class="course-card" [data]="element"></bundle>
      </ng-container>
    </ng-container>
    </div>
  </ng-container>

</div>
