import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogCategoryService} from '../../../shared/bundles/app/blog_category/blog_category.service';
import {UrlService} from '../../../services/urlService';
import {AppJobOfferBaseService} from '../../../shared/bundles/app/job_offer/job_offer.base.service';
import {AppCourseBaseService} from '../../../shared/bundles/app/course/course.base.service';
import {AppCourseService} from '../../../shared/bundles/app/course/course.service';
import {AppJobOfferService} from '../../../shared/bundles/app/job_offer/job_offer.service';
import {BaseComponent} from "../../base.component";

@Component({
  selector: 'bundle.latest-events',
  templateUrl: './bundle.latest.events.component.html',
  styleUrls: ['./bundle.latest.events.component.scss'],
  providers: []
})
export class BundleLatestEventsComponent extends BaseComponent implements OnInit{

    selectedEntity = null;

    protected jobService;
    protected coursesService;

    ngOnInit() {
      this.jobService = this._injector.get(AppJobOfferService);
      this.coursesService = this._injector.get(AppCourseService);
      this.getListBy(0);
    }

    async getListBy(index) {
      this._data = [];
      let elements = [];
      this.selectedEntity = index;
      switch (index) {
        case 0:
          elements = await this.jobService.getAll('?itemsPerPage=3').toPromise();
          break;
        case 1:
          elements = await this.coursesService.getAll('?itemsPerPage=3').toPromise()
          break;
      }
      this._data = elements.length > 0 ? elements : null
    }
}
