import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {UrlService} from '../../../services/urlService';
import {AppConfig} from '../../../config/app.config';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'bundle.footer-owner',
  templateUrl: './bundle.footer.owner.component.html',
  styleUrls: ['./bundle.footer.owner.component.scss'],
  providers: []
})

export class BundleFooterOwnerComponent implements OnInit{


    constructor(
    ) {}

    ngOnInit() {
    }

    getOwner() {
      return AppConfig.owner;
    }
}
