<div class="user-job-offers">

  <div class="article-content">
  <table>
    <thead>
    <tr>
      <th>{{'user_job_offer_table_name'|trans}}</th>
      <th>{{'user_job_offer_table_date'|trans}}</th>
      <th>{{'user_job_offer_table_status'|trans}}</th>
<!--      <th>Aplikowano</th>-->
<!--      <th>Status</th>-->
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let application of data">
      <tr>
        <td>{{application.jobOffer.title}}</td>
        <td>{{application.createdAt|date:'dd/MM/YYYY'}}</td>
        <td><span style="color: {{application.applicationStatus.color}}">{{application.applicationStatus.name}}</span></td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  </div>

</div>
