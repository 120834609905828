import {Component, Input} from '@angular/core';

import {CoreMessage} from '../../../services/CoreMessage';
import {FormFormService} from '../../../services/form.api.service';
import {ApiLink} from "../../../utils/utils";
import {FormBaseComponent} from "../../form.base.component";


@Component({
  selector: 'bundle.newsletter-footer',
  templateUrl: './bundle.newsletter.footer.component.html',
  styleUrls: ['./bundle.newsletter.footer.component.scss'],
  providers: []
})
export class BundleNewsletterFooterComponent extends FormBaseComponent{

    message : any;
    forValidationErrorList = [];

    // constructor(private formService: FormFormService) {}

    submitPost($event,data) {
      let formKey : string = 'NewsletterFormSubscribe';

      $event.preventDefault();
      this.formService.clearFormMessage(formKey );
      // console.log($form, $event, formvalue)
      this.formService.send(new FormData($event.target), ApiLink('/newsletter-subscription')).subscribe(response => {
        let result = this.formService.retriveResponse(formKey, response, false, true);
        if (result instanceof CoreMessage) {
          this.message = result;
        } else {
          console.log(result);
          this.forValidationErrorList = result;
        }
      })
    }

}
