<div class="user-consultation">

  <div class="article-content">
    <table *ngIf="data">
      <thead>
      <tr>
        <th>{{'user_consultation_table_proposed_date'|trans}}</th>
        <th>{{'user_consultation_table_comment'|trans}}</th>
        <th>{{'user_consultation_table_paid'|trans}}</th>

      </tr>
      </thead>
      <tbody>

      <ng-container *ngIf="data.length === 0; else consultations">
        <tr>
          <td colspan="3">{{'user_consultation_table_empty'|trans}}</td>
        </tr>
      </ng-container>

      <ng-template #consultations>
        <ng-container *ngFor="let consultation of data">
        <tr>
          <td>{{consultation.proposedDate|date:'dd-MM-yyyy HH:mm'}}</td>
          <td [innerHTML]="consultation.comment|safeHtml"></td>
          <td>
            <ng-container *ngIf="consultation.haveToBePaid; else notHaveToBePaid">
              <ng-container *ngIf="consultation.isPayed; else notPayed">
                <span>{{'user_consultation_consultation_paid'|trans}}</span>
              </ng-container>
              <ng-template #notPayed>
<!--                <span class="button">Opłać konsultacje</span>-->
                <a class="button" target="_blank" rel="nofollow" href="https://winpecker.devdigitalberry.pl/pay/consultation/{{consultation.id}}">{{'user_consultation_btn_pay'|trans}}</a>
              </ng-template>
            </ng-container>
            <ng-template #notHaveToBePaid>
              {{'user_consultation_not_required_paid'|trans}}
            </ng-template>
          </td>
        </tr>
        </ng-container>
      </ng-template>
      </tbody>
    </table>
  </div>

  <div class="form-blue">
      <bundle class="form" [formId]="7" (submitEvent)="formSubmited($event)"></bundle>
  </div>
</div>
