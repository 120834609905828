import {EventEmitter, Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHandler, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import {AppConfig} from '../config/app.config';
import {BehaviorSubject, Subject, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppService} from '../core/services/app.service';
import {ActivatedRoute} from "@angular/router";
import {getLocale, link} from "../utils/utils";
import {UrlService} from "./urlService";
import {from} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
class OwnHttpClient {

  constructor(protected httpClient: HttpClient, protected appService: AppService) {
  }


  refreshFetch
  refreshInProgress = new BehaviorSubject(false);

  order = []

  public post(url: string, body: any, options: any) {

    return new Observable(subscriber => {
      if (this.appService.canRefreshToken()) {
        if (!this.refreshFetch) {
          // return new Observable(subscriber => {
          this.refreshInProgress.subscribe(value => {
            if (!value){
              this.refreshInProgress.next(true)
              this.appService.refreshAccessToken().subscribe(data => {
                // this.httpClient.get(url, options)
                this.appService.saveToken(data);
                // subscriber.next(this.httpClient.post(url, body, options));

                this.httpClient.post(url, body, options).pipe(map(res => res)).subscribe(res => {
                  subscriber.next(res)
                  subscriber.complete()
                  this.refreshInProgress.next(false)
                });

                // subscriber.complete();
              })
            }
          })
          // });
        }
      }else {

        this.httpClient.post(url, body, options).pipe(map(res => res)).subscribe(res => {
          subscriber.next(res)
          subscriber.complete()
        });
      }
    })

    // return this.httpClient.post(url, body, options)

  }

  public get(url: string, options: any):Observable<any> {

    return new Observable(subscriber => {
      if (this.appService.canRefreshToken()) {
        if (!this.refreshFetch) {
          // return new Observable(subscriber => {
            this.refreshInProgress.subscribe(value => {
              if (!value){
                this.refreshInProgress.next(true)
                this.appService.refreshAccessToken().subscribe((data:any) => {
                  // this.httpClient.get(url, options)
                  this.appService.saveToken(data);
                  console.log(options);
                  options.header.delete('Authorization');
                  options.headers.append( 'Authorization', 'Bearer ' + data.access_token)
                  this.httpClient.get(url, options).pipe(map(res => res)).subscribe(res => {
                    subscriber.next(res)
                    subscriber.complete()
                    this.refreshInProgress.next(false)
                  });
                })
              }
            })
          // });
        }
      }else {
        this.httpClient.get(url, options).pipe(map(res => res)).subscribe(res => {
          subscriber.next(res)
          subscriber.complete()
        });
      }
    })

  }

  // refresh() {
  //   return new Promise((resolve, reject) => {
  //     if (this.appService.canRefreshToken()) {
  //       // this.order.push(callback);
  //       if (this.refreshInProgress === false) {
  //         this.refreshInProgress = true;
  //         this.appService.refreshAccessToken()
  //           .then(() => {
  //             // this.order.forEach(fn => fn())
  //             resolve(true);
  //             this.refreshInProgress = false;
  //           }).catch(err => {
  //           if (this.appService.checkCredentials()) {
  //             this.appService.logout();
  //           } else {
  //             window.location.reload()
  //           }
  //         })
  //       }
  //     } else {
  //        resolve(true);
  //     }
  //   })
  //
  // }

}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected request$: EventEmitter<any>;
  protected apiUrl: string;
  getAllCache: Observable<any>;

  public http: OwnHttpClient;
  public appService: AppService;
  refreshTokenInProgres = false

  constructor(
    protected _injector: Injector,
  ) {
    this.http = this._injector.get(OwnHttpClient);
    // this.http = new OwnHttpClient(this.appService);
    this.appService = this._injector.get(AppService);
    this.apiUrl = AppConfig.endpoints.serverApi;
    this.request$ = new EventEmitter();
  }

  protected handleError(error: any): Observable<any> {
    this.request$.emit('finished');

    console.log('handle error')

    console.log('error', error);
    console.log(error.status == 401);
    // if (error.status == 401) {
    //   console.log('neeed logout')
    //   if (this.appService.checkCredentials()) {
    //     this.appService.logout();
    //     this._injector.get(UrlService).redirect(link(getLocale('page_id_login')));
    //
    //   } else {
    //     this._injector.get(UrlService).redirect(link(getLocale('page_id_login')));
    //   }
    // }
    //
    // if (error instanceof Response) {
    //   return throwError(error.json()['error'] || 'backend server error');
    // }
    return throwError('backend server error');
  }

  public getByStaticUrl(url, options = this.appService.getOptions()) {
    // this.startRequest(url, options)
    return this.http.get(url, options).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  public staticPost(url: string, data: any, options = this.appService.getOptions()) {
    return this.http.post(url, data, options).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      }),
      catchError(error => this.handleError(error))
    )
  }

  public getPlainText(url) {
    return this.http.get(url, {responseType: 'text'});
  }

}
