import {Component, Input, OnInit, Output, EventEmitter, Injector, AfterContentInit, OnDestroy} from '@angular/core';
import {UrlService} from '../../../services/urlService';
import {BaseComponent} from '../../base.component';
import {ApiService} from '../../../services/api.service';
import {ConverterService} from '../../../services/converter.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {getLocale} from "../../../utils/utils";

@Component({
  selector: 'bundle.category-nav',
  templateUrl: './bundle.category.nav.component.html',
  styleUrls: ['./bundle.category.nav.component.scss'],
  providers: [Location]
})

export class BundleCategoryNavComponent extends BaseComponent implements OnInit{

    params:any = {};


    constructor(
      protected _injector: Injector,
      protected router: Router,
      // private route: ActivatedRoute
    ){
      super(_injector);
      this.urlService.urlParams.subscribe((params:any) => {
          this.params = params;
      });
    }

    @Output() selectedElementEmitter = new EventEmitter();

    @Input()
    set elements(elements) {
      this._data = elements;
      this.onSetData();
    }

    protected onSetData(data: any = this._data) {
      if (this.params['cmsid'] === undefined) {
        if (this.data[0].allButton == 'false'){
          // this.urlService.parseUrlToParamsObj(this.data[0].url);
          this.router.navigate([this.urlService.getUrl() + "," +this.data[0].url])

          // this.urlService.changeUrl(this.data[0].url);
        }else {
          this.urlService.addSlugParameter(getLocale('category_all'), '0');
        }
      }
    }

    ngOnInit() {}

    getUrl() {
        return this.urlService.getUrl();
    }

    createUrl() {
      return this.urlService.getUrl()+',' + getLocale('wszystkie', false, true)+',0'
    }

}
