import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogCategoryService} from '../../../shared/bundles/app/blog_category/blog_category.service';
import {UrlService} from '../../../services/urlService';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.blog-categories',
  templateUrl: './bundle.blog.categories.component.html',
  styleUrls: ['./bundle.blog.categories.component.scss'],
  providers: []
})

export class BundleBlogCategoriesComponent extends BaseComponent {


}
