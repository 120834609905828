<div class="faq-card" *ngIf="data">

<h2>{{data.title}}</h2>
<ng-container *ngFor="let element of data.elements;let i = index">
  <div>
    <div class="label">
    {{element.fName}}
    <app-core-svg class="not-rotate" [url]="'/assets/staticresource/project/next_blue.svg'" id="app_core_svg_{{data.id}}_{{i}}"
                  (click)="toogle('collapse_' + data.id + '_' + i)"></app-core-svg>
    </div>


    <div class="content hide" id="collapse_{{data.id}}_{{i}}">
      <div class="article-content" [innerHTML]="element.content"></div>
    </div>

  </div>
</ng-container>



</div>
