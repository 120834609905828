import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.offert-card',
  templateUrl: './bundle.offert.card.component.html',
  styleUrls: ['./bundle.offert.card.component.scss'],
  providers: []
})

export class BundleOffertCardComponent extends BaseComponent implements OnInit{

    ngOnInit(): void {}

    public getTags(tagsElements): string[] {
      return tagsElements.map(el => el.tag).map(el => `<span class="tag">#${el}</span>`).join("");
    }
}
