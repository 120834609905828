import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../base.component";
import {AppJobApplication} from "../../../../shared/bundles/app/job_application/job_application.model";
import {AppJobApplicationService} from "../../../../shared/bundles/app/job_application/job_application.service";
import {ApiLink} from "../../../../utils/utils";


@Component({
    selector: 'bundle.user-consultation',
    templateUrl: './user.consultation.component.html',
    styleUrls: ['./user.consultation.component.scss'],
})

export class UserConsultationComponent extends BaseComponent implements OnInit{

  ngOnInit() {
    this.getConsultation()
  }

  getConsultation() {
    this.apiService.getByStaticUrl(ApiLink('/user-consultations')).subscribe(data => {
      console.log(data);
      this.data = data;
    })
  }

  formSubmited($event) {
    this.getConsultation();
  }

}
