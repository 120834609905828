import {Component, Injector, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {AppBlogEntryService} from '../../../shared/bundles/app/blog_entry/blog_entry.service';
import {UrlService} from '../../../services/urlService';
import {AppCourseService} from '../../../shared/bundles/app/course/course.service';
import {BaseComponent} from '../../base.component';

@Component({
  selector: 'bundle.courses-list',
  templateUrl: './bundle.courses.list.component.html',
  styleUrls: ['./bundle.courses.list.component.scss'],
  providers: []
})

export class BundleCoursesListComponent extends BaseComponent implements OnInit{
    protected courseService

    constructor(
      protected _injector: Injector
    ) {
      super(_injector);
      this.courseService = _injector.get(AppCourseService);
    }

    ngOnInit() {
      this.urlService.urlParams.subscribe(params => {
        this.courseService.getByParams(this.urlService.parseParamsToFetch(params)).subscribe(data => {
          this._data = data;
        })
        // this.courseService.getByCategory()
      })
      // this.courseService.getAll().subscribe(data => {
      //   this._elements = data;
      //   console.log('courses', data);
      // })
    }
}
