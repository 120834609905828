import {Component, Input, OnInit, SecurityContext} from '@angular/core';
import {ConverterService} from '../../../services/converter.service';
import {ApiService} from '../../../services/api.service';
import {BaseComponent} from "../../base.component";

@Component({
  selector: 'bundle.faq-card',
  templateUrl: './faq.card.component.html',
  styleUrls: ['./faq.card.component.scss'],
  providers: []
})

export class BundleFaqCardComponent extends BaseComponent{

  protected onSetData(data: any = this._data) {
    console.log('data on faq input', data);
    if (Array.isArray(data)){
      data.forEach(el => {
        el.id = this.getUuid();
      })
    }else {
      data.id = this.getUuid();
    }
    super.onSetData(data);
  }

  public toogle(id, ev) {
    let $svg = document.querySelector(`#${id.replace('collapse_', 'app_core_svg_')} > span`);
    let $ = document.querySelector(`#${id}`);
    if ($.classList.contains('show')) {
      $.classList.remove('show');
      $.classList.add('hide');
      $svg.classList.remove('rotate');
      $svg.classList.add('not-rotate');

    }else {
      $svg.classList.add('rotate');
      $svg.classList.remove('not-rotate');
      $.classList.remove('hide');
      $.classList.add('show');
    }
  }

  public getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
