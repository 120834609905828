import {Component, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CoreOwnerData} from './owner_data.model';
import {Observable} from 'rxjs/Observable';

import {CoreOwnerDataBaseService} from './owner_data.base.service';
import {AppService} from '../../../../core/services/app.service';
import {AppConfig} from '../../../../config/app.config';
import {catchError, map} from 'rxjs/operators';
import {ApiLink} from "../../../../utils/utils";


@Injectable({
    providedIn: 'root'
})
export class CoreOwnerDataService extends CoreOwnerDataBaseService {

  getCurrent(): Observable<any> {
    this.request$.emit('starting');
    console.log(this.http);
    return this.http.get( ApiLink('/core/owner_datas?is_default=1'), this.appService.getOptions()).pipe(
      map(response => {
        this.request$.emit('finished');
        return response;
      })
      ,catchError(error => this.handleError(error))
  )
  }

}
